import AxiosService from '@/services/AxiosServices'

export default {

  listCountries() {
    const locale = localStorage.getItem('locale') ?? 'fr'
    return AxiosService.callApi().get('/countries?lang=' + locale)
  },
  edit(id, data) {
    return AxiosService.callApi().put('/countries/' + id, data)
  },
  sortCountriesList(countriesList) {
    return countriesList.sort(function(a, b) {
      var textA = a.name;
      var textB = b.name;
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

  }
}
