import ProvideNumberPerRollsService from '@/services/ProvideNumberPerRollsService'

export default {
  state: {
    provideNumberPerRolls: {},
    provideNumbersPerRolls: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_PROVIDE_NUMBER_PER_ROLLS(state, provideNumberPerRolls) {
      state.provideNumbersPerRolls.push(provideNumberPerRolls)
    },
    SET_PROVIDE_NUMBERS_PER_ROLLS(state, provideNumbersPerRolls) {
      state.provideNumbersPerRolls = provideNumbersPerRolls
    },
    SET_PROVIDE_NUMBERS_PER_ROLLS_TOTAL(state, total) {
      state.total = total
    },
    SET_PROVIDE_NUMBERS_PER_ROLLS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_PROVIDE_NUMBER_PER_ROLLS(state, provideNumberPerRolls) {
      state.provideNumberPerRolls = provideNumberPerRolls
    },
  },
  actions: {
    /**
     * Fetch in state list of provideNumbersPerRolls
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchProvideNumbersPerRolls({ commit }, { perPage, page, order, search }) {
      return ProvideNumberPerRollsService.getProvideNumbersPerRolls(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_PROVIDE_NUMBERS_PER_ROLLS', response.data.list)
          commit('SET_PROVIDE_NUMBERS_PER_ROLLS_TOTAL', response.data.total)
          commit('SET_PROVIDE_NUMBERS_PER_ROLLS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on provideNumbersPerRolls list: ', error)
        })
    },
    /**
     * Fetch in state a provideNumberPerRolls
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchProvideNumberPerRolls({ commit }, id)
    {
      ProvideNumberPerRollsService.getProvideNumberPerRolls(id)
        .then(response => {
          commit('SET_PROVIDE_NUMBER_PER_ROLLS', response.data)
        })
        .catch((error) => {
          console.log('There was an error on provideNumbersPerRolls : ', error)
        })
    },
    /**
     * Create provideNumberPerRolls
     *
     * @param commit
     * @param provideNumberPerRolls
     */
    createProvideNumberPerRolls({ commit }, provideNumberPerRolls)
    {
      return ProvideNumberPerRollsService.create(provideNumberPerRolls)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_PROVIDE_NUMBER_PER_ROLLS', provideNumberPerRolls)
          return Promise.resolve()
        })
    },
    /**
     * Update provideNumberPerRolls
     *
     * @param commit
     * @param id
     * @param provideNumberPerRolls
     * @returns {*}
     */
    updateProvideNumberPerRolls({ commit }, {id, provideNumberPerRolls})
    {
      return ProvideNumberPerRollsService.edit(id, provideNumberPerRolls)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_PROVIDE_NUMBER_PER_ROLLS', response.data)
        })
    },
    /**
     * Delete provideNumberPerRolls
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteProvideNumberPerRolls({ commit }, {id}) {
      return ProvideNumberPerRollsService.delete(id)
    }
  },
  getters: {
    /**
     * Get provideNumbersPerRolls by id in list provideNumbersPerRolls
     *
     * @param state
     * @returns {function(*): *}
     */
    getProvideNumberPerRollsById: state => id => {
      return state.provideNumbersPerRolls.find(provideNumberPerRolls => provideNumberPerRolls.id === id)
    }
  }
}
