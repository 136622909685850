<template>
  <div id="page-shape-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.shape.createShape') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'shape-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudShape
        :shape="shape"
        :actionForm="'create'"
    />
  </div>
</template>

<script>


export default {
  data() {
    return {
      shape: {}
    }
  },
  components : {
    FormCrudShape: () => import('@/components/pages/shape/FormCrudShape.vue')
  }
}
</script>

<style scoped>

</style>
