import OrderRequestCancelService from '@/services/OrderRequestCancelService'

export default {
  state: {
    requestCancels: [],
    requestCancel: {},
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_REQUEST_CANCEL(state, requestCancel) {
      state.requestCancels.push(requestCancel)
    },
    SET_REQUEST_CANCELS(state, requestCancels) {
      state.requestCancels = requestCancels
    },
    SET_REQUEST_CANCELS_TOTAL(state, total) {
      state.total = total
    },
    SET_REQUEST_CANCELS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_REQUEST_CANCEL(state, requestCancel) {
      state.requestCancel = requestCancel
    },
  },
  actions: {
    /**
     * Fetch in state list of order request cancel
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     * @param resellerId
     */
    fetchOrderRequestCancels({ commit }, { perPage, page, order, search, resellerId }) {
      return OrderRequestCancelService.getOrderRequestCancels(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null,
        resellerId
      )
        .then(response => {
          commit('SET_REQUEST_CANCELS', response.data.list)
          commit('SET_REQUEST_CANCELS_TOTAL', response.data.total)
          commit('SET_REQUEST_CANCELS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on order request cancels list: ', error)
        })
    },
    /**
     * Update status
     *
     * @param commit
     * @param id
     * @param orderRequestCancel
     * @param respond
     * @returns {*}
     */
    updateOrderRequestCancelStatus({ commit }, {id, orderRequestCancel, respond})
    {
      let model = {
        orderRequestCancel: orderRequestCancel,
        respond: respond
      }

      return OrderRequestCancelService.editOrderRequestCancel(id, model)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_REQUEST_CANCEL', response.data)
        })
    },
    cancelRespond({ commit }, {id, orderRequestCancel, respond})
    {
      let model = {
        orderRequestCancel: orderRequestCancel,
        respond: respond
      }

      return OrderRequestCancelService.cancelRespond(id, model)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_REQUEST_CANCEL', response.data)
        })
    }
  },
  getters: {
    /**
     * Get order request cancel by id in list order request cancels
     *
     * @param state
     * @returns {function(*): *}
     */
    getOrderRequestCancelById: state => id => {
      return state.requestCancels.find(requestCancel => requestCancel.id === id)
    }
  }
}
