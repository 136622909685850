<template>
  <div class="page-scenario-show">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <div>
        <h5>
          {{ $t('pages.scenario.detailsScenario') }}
          <strong>{{ ScenarioModule.scenario.name }}</strong>
        </h5>
      </div>

      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'scenario-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <h3>{{ $t('common.globalInfo') }}</h3>
      <hr />

      <div class="d-flex mb-3">
        <span class="w-25"><strong>ID</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.id }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>Uid</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.uid }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('common.name') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.name }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.scenario.qtyMin') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.minQuantity }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.scenario.qtyMax') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.maxQuantity }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.scenario.heightMin') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.minHeight }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.scenario.heightMax') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.maxHeight }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.scenario.widthMin') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.minWidth }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.scenario.widthMax') }}</strong></span>
        <span class="ml-2">{{ ScenarioModule.scenario.maxWidth }}</span>
      </div>

      <h3 class="mt-4 mb-4">Config(s)</h3>
      <hr />

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('common.status') }}</strong></span>
        <b-badge class="ml-2" v-if="ScenarioModule.scenario.status" variant="success">{{ $t('common.yes') }}</b-badge>
        <b-badge class="ml-2" v-else variant="danger">{{ $t('common.no') }}</b-badge>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      isActive: '',
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchScenario', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    ...mapState(['ScenarioModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
