<template>
  <div id="page-category-edit">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <div>
        <h5>
          {{ $t('pages.category.editCategory') }}:
          <strong>{{ CategoryModule.category.name }}</strong>
        </h5>
      </div>
      <div>
        <router-link
            class="btn btn-outline-primary mb-3"
            :to="{ name: 'category-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudCategory
        :category="CategoryModule.category"
        :id="CategoryModule.category.id"
        :selected-pose="getSelectedPose"
        :list-provide-number-per-rolls="getListIdProvideNumberPerRolls"
        :list-mandrels="getListIdMandrels"
        :list-orientations="getListIdOrientations"
        :list-papers="getListIdPapers"
        :actionForm="'edit'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchCategory', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    getSelectedPose() {
      return (this.CategoryModule.category && this.CategoryModule.category.pose) ? this.CategoryModule.category.pose.id : null
    },
    /**
     * Create list id of provide number per rolls
     * @returns {[]}
     */
    getListIdProvideNumberPerRolls()
    {
      let provideNumberId = []

      if(this.CategoryModule.category.provideNumberPerRolls) {
        this.CategoryModule.category.provideNumberPerRolls.forEach(function(provideNumberPerRolls) {
          provideNumberId.push(provideNumberPerRolls.id)
        })
      }

      return provideNumberId
    },
    /**
     * Create list id of mandrel
     * @returns {[]}
     */
    getListIdMandrels() {
      let mandrelsId = []

      if(this.CategoryModule.category.mandrels) {
        this.CategoryModule.category.mandrels.forEach(function(mandrel) {
          mandrelsId.push(mandrel.id)
        })
      }

      return mandrelsId
    },
    /**
     * Create list id of orientation
     * @returns {[]}
     */
    getListIdOrientations() {
      let orientationsId = []

      if(this.CategoryModule.category.orientations) {
        this.CategoryModule.category.orientations.forEach(function(orientation) {
          orientationsId.push(orientation.id)
        })
      }

      return orientationsId
    },
    /**
     * Create list id of papers
     * @returns {[]}
     */
    getListIdPapers() {
      let papersId = []

      if(this.CategoryModule.category.papers) {
        this.CategoryModule.category.papers.forEach(function(paper) {
          papersId.push(paper.id)
        })
      }

      return papersId
    },
    ...mapState(['CategoryModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    FormCrudCategory: () => import('@/components/pages/category/FormCrudCategory')
  }
}
</script>

<style scoped>

</style>
