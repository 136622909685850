import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of Mandrels
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getMandrels(perPage, page, order, search) {
    let url = 'admin/mandrels'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  /**
   * Get Mandrel by id
   *
   * @param id
   * @returns {*}
   */
  getMandrel(id) {
    return AxiosService.callApi().get('/admin/mandrels/' + id)
  },
  /**
   * Create Mandrel
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/mandrels', data)
  },
  /**
   * Edit Mandrel
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/mandrels/' + id, data)
  },
  /**
   * Delete Mandrel
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/mandrels/' + id)
  }
}
