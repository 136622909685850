<template>
  <div class="bs-app" id="page-login">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card border-0 shadow p-2">
            <header class="card-header text-center">
              <div id="header-logo">
                <div class="logo">
                  <img :src="require('../../../public/images/logo-adesa.png')" alt="Logo Adesa">
                </div>
              </div>
            </header>
            <div class="card-body">
              <form @submit.prevent="tryToLogin">
                <div class="form-group field-text">
                  <input
                      v-model="login.username"
                      class="form-control"
                      type="text"
                      name="email"
                      id="email"
                      value
                      :placeholder="$t('loginForm.identifiers')"
                  />
                </div>

                <div class="form-group field-password">
                  <input
                      v-model="login.password"
                      class="form-control"
                      type="password"
                      name="password"
                      id="password"
                      value
                      :placeholder="$t('loginForm.password')"
                  />
                </div>

                <div>
                  <router-link class="text-center nav-link p-0" :to="{ name: 'forgot-password' }">
                    {{ $t('loginForm.forgotPassword') }}
                  </router-link>
                </div>

                <div class="text-center mt-2" v-if="isFormOk">
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('loginForm.connectionProgress') }} ...</div>
                </div>

                <button class="mt-3 mt-md-3 btn btn-block btn-primary" type="submit" name="button">
                  {{ $t('loginForm.login') }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      login: {
        username: '',
        password: '',
        options: 'bo-front'
      },
      authError: null,
      isAuthError: false,
      isFormOk: false
    }
  },
  methods: {
    clickErrorForm() {
      this.isAuthError = false
    },
    tryToLogin() {
      this.isFormOk = true

      this.$store
        .dispatch('login', this.login)
        .then(() => {
          this.isAuthError = false

          // Redirect
          this.$router.push({ name: 'dashboard-index', params: { id: this.$store.getters.getCurrentUser.id } })
        }).catch((error) => {
          this.$bvToast.toast(error.message, {
            title: 'ERREUR !',
            variant: 'danger',
            solid: true
          })

            this.isAuthError = true
            this.isFormOk = false
        })
    }
  },
  computed: {
    ...mapState(['AuthModule']),
  }
}
</script>
