import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of Orientations
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getOrientations(perPage, page, order, search) {
    let url = 'admin/orientations'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  /**
   * Get Orientation by id
   *
   * @param id
   * @returns {*}
   */
  getOrientation(id) {
    return AxiosService.callApi().get('/admin/orientations/' + id)
  },
  /**
   * Create Orientation
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/orientations', data)
  },
  /**
   * Edit Orientation
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/orientations/' + id, data)
  },
  /**
   * Delete Orientation
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/orientations/' + id)
  }
}
