import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of CmsCalculator
   *
   * @returns {*}
   */
  getCmsCalculatorEntries(typeCmsCalculator) {
    return AxiosService.callApi().get('/admin/cms?_type=' + typeCmsCalculator)
  },
  /**
   * Get CmsCalculator by id
   *
   * @param id
   * @returns {*}
   */
  getCmsCalculatorEntry(id) {
    return AxiosService.callApi().get('/admin/cms' + id)
  },
  /**
   * Create CmsCalculator
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/cms', data)
  },
  /**
   * Edit CmsCalculator
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/cms/' + id, data)
  },
  /**
   * Delete CmsCalculator
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/cms/' + id)
  }
}
