import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of Order not conformity list
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @param resellerId => is request is for Reseller or Admin
   * @returns {*}
   */
  getOrderNotConformityList(perPage, page, order, search, resellerId) {
    let url = 'admin/order_not_conformity_list'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    // Reseller
    if(resellerId !== null) {
      let isNoFilter = (perPage === null) ? '?': '&'
      url += isNoFilter + '_resellerId=' + resellerId
    }

    return AxiosService.callApi().get(url)
  },
  countOrderRequestNotConformityNotResponded(resellerId) {
    return AxiosService.callApi().get('admin/order_not_conformity/count/' + resellerId)
  },
  /**
   * Get Order not conformity by Order ID
   *
   * @param orderId
   * @param orderStatusExtId
   * @returns {*}
   */
  getOrderNotConformityByOrderId(orderId, orderStatusExtId = null) {
    let url =  '/admin/order_not_conformity/' + orderId;
    if (orderStatusExtId) {
      url += '?_order_status_ext_id=' + orderStatusExtId
    }
    return AxiosService.callApi().get(url)
  },
  createOrderNotConformity(data) {
    return AxiosService.callApi().post('/admin/order_not_conformity', data)
  },
  responseResellerOrderNotConformity(model) {
    return AxiosService.callApi().patch('/admin/order_not_conformity/' + model.id, model)
  },
  uploadFileOrderNotConformity(model) {
    return AxiosService.callApi().post('/admin/order_not_conformity/upload/' + model.id, model)
  }
}
