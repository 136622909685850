<template>
  <div class="bs-app" id="page-translate-export-json-xlsx" v-if="this.$store.getters.getCurrentUser.username === 'alexandre@weggs.fr'">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="clipboard-check" font-scale="1.5" variant="primary"></b-icon>
        Export Super Admin
      </h3>
    </div>

    <hr />

    <div class="mt-4 col-6 p-0 d-flex">
      <div class="mt-4">
        <button class="btn btn-outline-success mt-2" v-on:click="showInputJsonToXlsx = true">
          Export JSON to XLSX
        </button>
      </div>

      <div class="d-flex flex-column ml-4">
        Import XLSX to JSON
        <input class="btn btn-outline-primary" type="file" @change="importXlsxToJson" />
      </div>
    </div>

    <div class="json-to-xlsx-form mt-4" v-if="showInputJsonToXlsx">
      <div class="d-flex justify-content-between">
        <h2>Export JSON to XLSX</h2>
        <button class="btn btn-warning text-white mb-2" v-on:click="showInputJsonToXlsx = false">X</button>
      </div>
      <textarea class="form-control" style="height: 500px;" v-model="jsonToXlsx"></textarea>
      <div class="w-100 d-flex justify-content-end">
        <button class="btn btn-success mt-2" v-on:click="exportJsonToXlsx">Export JSON</button>
      </div>
    </div>
    <div class="mt-4" v-if="showJsonFromXlsx">
      <div class="d-flex justify-content-between">
        <h2>Import XLSX to JSON</h2>
        <button class="btn btn-warning text-white mb-2" v-on:click="showJsonFromXlsx = false">X</button>
      </div>
      <div class="card card-json-translate">
        <div class="card-body">
          <div>
            <button class="btn btn-outline-secondary" v-on:click="copyJson">Copy</button>
          </div>
          <pre>
            <code class="json-code">{{ jsonFromXlsx }}</code>
          </pre>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-4" v-else>
    <span class="">You don't have access to this pages, thx to go back to home page</span>
    <br />
    <router-link
        class="btn btn-outline-primary mt-4"
        :to="{ name: 'dashboard-index' }"
    >
      Back to home page
    </router-link>
  </div>
</template>

<script>

import * as XLSX from 'xlsx'

export default {
  data() {
    return {
      showInputJsonToXlsx: false,
      jsonToXlsx: '',
      showJsonFromXlsx: false,
      jsonFromXlsx: ''
    }
  },
  methods: {
    importXlsxToJson($event) {
      this.$swal({
        title: 'Do you want to Import XLSX to JSON ?',
        text: 'IMPORT',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: 'Import'
      }).then((result) => {

        if (result.value) {
          this.showJsonFromXlsx = true
          this.xlsxToJson($event)

          this.$bvToast.toast('Import XLSX To JSON', {
            title: 'Import successfully',
            variant: 'success',
            solid: true
          })
        }
      })
    },
    exportJsonToXlsx() {
      this.$swal({
        title: 'Do you want to Export JSON to XLSX ?',
        text: 'EXPORT',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: 'Export'
      }).then((result) => {

        if (result.value) {
          const data = XLSX.utils.aoa_to_sheet(this.buildIndex(JSON.parse(this.jsonToXlsx)))
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'data')
          XLSX.writeFile(wb,'export-trad.xlsx')

          this.$bvToast.toast('Export JSON To XLSX', {
            title: 'Export successfully',
            variant: 'success',
            solid: true
          })
        }
      })
    },
    buildIndex(json) {
      // Init var
      let that = this
      let arrayToXlsx = []

      let jsonToArray = Object.keys(json).map((key) => [key, json[key]])
      jsonToArray.forEach(function(subIndex) {
        arrayToXlsx.push(subIndex[0])
        that.recursiveIndex(arrayToXlsx, subIndex, subIndex[0])
      })

      // Remove entry is not an array
      arrayToXlsx.forEach(function(item, index) {
        if (typeof item === 'string' || item instanceof String) {
          delete arrayToXlsx[index]
        }
      })

      return arrayToXlsx
    },
    recursiveIndex(array, item, indexConcat) {
      // Init var
      let that = this

      // Start recursive
      if (typeof item[1] === 'object') {
        let subIndexToArray = Object.keys(item[1]).map((key) => [key, item[1][key]])
        subIndexToArray.forEach(function(subIndex) {
          if (typeof subIndex[1] === 'string' || subIndex[1] instanceof String) {
            array.push([indexConcat + '.' + subIndex[0], subIndex[1]])
          } else if (typeof subIndex[1] === 'object') {
            that.recursiveIndex(array, subIndex, indexConcat + '.' + subIndex[0])
          }
        })
      }
    },
    xlsxToJson($event) {
        const file = $event.target.files[0]
        // Boilerplate to set up FileReader
        const reader = new FileReader()
        reader.onload = (e) => {
          // Parse data
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          // Get first worksheet
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          // Convert array of arrays
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 })

          return this.xlsxFileToJson(data)
        }

        reader.readAsBinaryString(file)
    },
    xlsxFileToJson(array) {
      let xlsxToJsonArray = {}
      array.forEach(function(item) {
        // Handle Index
        if(item[0]) {
          let splitIndex = item[0].split('.')

          let target = xlsxToJsonArray
          while (splitIndex.length > 1) {
            const index = splitIndex.shift()
            target = target[index] = target[index] || {}
          }

          target[splitIndex[0]] = item[1]
        }
      })

      this.jsonFromXlsx = xlsxToJsonArray
    },
    copyJson()
    {
      var dummy = document.createElement("textarea");
      document.body.appendChild(dummy)
      dummy.value = document.getElementsByClassName('json-code')[0].textContent
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.$bvToast.toast('Json copied', {
        title: 'Json successfully copied',
        variant: 'success',
        solid: true
      })
    }
  },
  computed: {
  }
}
</script>
