<template>
  <div class="row h-100" id="page-create-user-password">

    <div v-if="!isErrors && !formSend" class="col-4 m-auto">
      <h2 class="text-center mb-5">
        Merci de renseigner votre nouveau mot de passe
      </h2>

      <form @submit.prevent="editPassword">
        <div class="form-group">
          <input
              class="form-control"
              id="password"
              v-model="user.password"
              type="password"
              :placeholder="placeholderInput"
          >
        </div>

        <input type="submit" class="btn btn-lg btn-block btn-primary" :value="valueButtonSubmit" />
      </form>
    </div>
    <div v-else-if="isErrors" class="col-4 m-auto text-center">
      <p v-if="errors.error_wrong_user">{{ errors.error_wrong_user }}</p>
      <p v-if="errors.error_token">{{ errors.error_token }}</p>
      <p>Merci de contacter votre administrateur</p>
    </div>
    <div class="col-4 m-auto text-center" v-else-if="formSend">
      <div class="text-center mt-2">
        <b-spinner variant="success" label="Spinning"></b-spinner>
        <div class="text-primary">Chargement en cours ...</div>
      </div>
    </div>
  </div>
</template>

<script>
import ResetPasswordRequestService from '@/services/ResetPasswordRequestService'

export default {
  data() {
    return {
      isErrors: '',
      formSend: false,
      valueButtonSubmit: 'Changer mon mot de passe',
      placeholderInput: 'Entrer votre mot de passe',
      user: {
        id: '',
        password: '',
        token: this.$route.params.token
      }
    }
  },
  created() {

  },
  methods: {
    /**
     * Update user (submit form)
     */
    editPassword() {
      ResetPasswordRequestService.changePasswordRequest(
          this.$route.params.token,
          this.$route.params.id,
          { password: this.user.password }
      )
          .then((response) => {
            if(response.data !== 200) {
              if(response.data.error_token) {
                this.$bvToast.toast(response.error_token, {
                  title: 'ERREUR !',
                  variant: 'danger',
                  solid: true
                })
              } else if (response.data.error_wrong_user) {
                this.$bvToast.toast(response.error_wrong_user, {
                  title: 'ERREUR !',
                  variant: 'danger',
                  solid: true
                })
              }
            } else {
              this.$bvToast.toast('Vous allez être redirigé vers la page de connexion', {
                title: 'Votre nouveaux mot de passe à été changé',
                variant: 'success',
                solid: true
              })

              this.formSend = true

              this.redirectAndRefresh()
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },
    /**
     * Redirect to user-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      setTimeout( () => this.$router.push({ path: '/login'}), 5000);
    }
  },
  computed: {
  }
}
</script>
