<template>
  <div id="page-mandrel-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.mandrel.createMandrel') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'mandrel-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudMandrel
        :mandrel="mandrel"
        :actionForm="'create'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      mandrel: {}
    }
  },
  components : {
    FormCrudMandrel: () => import('@/components/pages/mandrel/FormCrudMandrel')
  }
}
</script>

<style scoped>

</style>
