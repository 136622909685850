// SERVICE
import AxiosService from '@/services/AxiosServices'
import UserService from '@/services/UserService'
import store from '../../store/index'

export default {
  state: {
    token: null,
    currentUser: null,
    adminUser: null
  },
  mutations: {
    SET_USER_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('auth.userToken', token)
    },
    SET_CURRENT_USER(state, data) {
      state.currentUser = data
      setLocalState('auth.user', data)
    },
    SET_ADMIN_USER(state, data) {
      state.adminUser = data
    }
  },
  actions: {
    /**
     *
     * @param commit
     * @param dispatch
     * @param loginModel
     * @returns {*}
     */
    login({ commit, dispatch }, loginModel) {
      // Call axios
      return AxiosService.getToken(loginModel)
        .then((response) => {
          // Set in store and local storage token
          commit('SET_USER_TOKEN', response.data.token)
          localStorage.setItem('locale', (window.localStorage.getItem('locale')) ? window.localStorage.getItem('locale') : 'fr')

          // Get user form token
          return Promise.resolve(dispatch('getUserFromToken', response.data.token))
        })
        .catch((error) => {
          if (error && error.code === 401) {
            this.logout({ commit }, true)
          }

          // Return error for login methods in login.vue
          return Promise.reject(error.response.data)
        })
    },
    connectAs({commit}, obj)
    {
      UserService.connectAs(obj.adminId, obj.id)
        .then((response) => {
          // Logout current User
          commit('SET_USER_TOKEN', null)
          commit('SET_CURRENT_USER', null)

          // Set admin id in local storage
          setLocalState('auth.adminId', obj.adminId)

          // Set in store and local storage token
          commit('SET_USER_TOKEN', response.data.userConnectAsToken)
          commit('SET_ADMIN_USER', response.data.adminUser)

          // Get user form token
          return Promise.resolve(
          UserService
            .getUserByToken(response.data.userConnectAsToken)
            .then((response) => {
              commit('SET_CURRENT_USER', response.data)

              window.location.href = '/'
            })
            .catch((error) => {
              if (error && error.code === 401) {
                this.logout({ commit }, true)
              }

              // Return error for login methods in login.vue
              return Promise.reject(error)
            })
          )
        })
    },
    backToAdmin({commit}) {
      // Logout current User
      commit('SET_USER_TOKEN', null)
      commit('SET_CURRENT_USER', null)

      // Set admin id in local storage
      localStorage.removeItem('auth.adminId')

      // Set in store and local storage token
      commit('SET_USER_TOKEN', store.getters.getAdminUser.api_token)

      // Get user form token
      return UserService
        .getUserByToken(store.getters.getAdminUser.api_token)
        .then((response) => {
          commit('SET_CURRENT_USER', response.data)

          window.location.href = '/'
        })
        .catch((error) => {
          if (error && error.code === 401) {
            this.logout({ commit }, true)
          }

          // Return error for login methods in login.vue
          return Promise.reject(error)
        })
    },
    /**
     * Fetch in state a user
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCurrentUser({ commit }, id)
    {
      UserService.getUser(id)
        .then(response => {
          commit('SET_CURRENT_USER', response.data)
        })
        .catch((error) => {
          console.log('There was an error on user: ', error)
        })
    },
    /**
     * Get user by token (id)
     *
     * @param commit
     * @param dataToken
     * @returns {*}
     */
    getUserFromToken({commit}, dataToken)
    {
      // Call axios (user)
      return UserService
        .getUserByToken(dataToken)
        .then((response) => {
          commit('SET_CURRENT_USER', response.data)
          commit('SET_LOCALE', (window.localStorage.getItem('locale')) ? window.localStorage.getItem('locale') : 'fr')
        })
        .catch((error) => {
          if (error && error.code === 401) {
            commit('SET_CURRENT_USER', null)
          }

          // Return error for login methods in login.vue
          return Promise.reject(error)
        })
    },
    // Logs out the current user.
    logout({ commit }, isRedirect) {
      commit('SET_USER_TOKEN', null)
      commit('SET_CURRENT_USER', null)

      if(isRedirect) {
        window.location.href = 'login'
      }

      // Delete vuex from localStorage
      window.localStorage.removeItem('vuex')
      window.localStorage.removeItem('auth.user')
      window.localStorage.removeItem('auth.userToken')

      // Clear all state
      if(isRedirect) {
        let clearState = {};
        Object.keys(this.$state).forEach(key => {
          clearState[key] = null
        });
        this.$store.replaceState(clearState)
      }
    },
  },
  getters: {
    /**
     * Whether the user is currently logged in.
     *
     * @param state
     * @returns {boolean}
     */
    loggedIn(state) {
      return !!state.currentUser
    },
    /**
     * Get current user
     *
     * @param state
     * @returns {null}
     */
    getCurrentUser(state) {
      return state.currentUser
    },
    getAdminUser(state) {
      return state.adminUser
    },
    /**
     * check if user is admin
     *
     * @param state
     * @returns {boolean}
     */
    userIsAdmin(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_ADMIN');
    },
    userIsReseller(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_RESELLER');
    },
    userIsReader(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_READER');
    },
  }
}

// ===
// Private helpers
// ===

/**
 * Set local state
 *
 * @param key
 * @param state
 */
function setLocalState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
