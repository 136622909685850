<template>
  <div class="page-order-show">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <div>
        <h5>
          {{ $tc('common.detailsOf', 0) }} {{ $tc('common.order', 0) }}
          <strong class="text-secondary">(# {{ OrderModule.order.id }})</strong>
        </h5>
      </div>

      <div>
        <router-link
            v-if="this.$route.query"
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'order-index'}"
        >
          {{ $t('common.back') }}
        </router-link>
        <router-link
           v-else
           class="btn btn-outline-primary mt-2 mt-md-0"
           :to="{ name: this.$route.query['paramFrom'] }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    <div class="d-flex">
      {{/* Right side */}}
      <div class="col-8 pl-2">
        {{/* Global info */}}
        <div class="card border-0 shadow p-3">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h4>{{ $t('common.globalInfo') }}</h4>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex mb-3">
              <span class="w-25"><strong>ID</strong></span>
              <span>{{ OrderModule.order.id }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.internalReference') }}</strong></span>
              <span>{{ OrderModule.order.internalReference }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.status') }}</strong></span>
              <div class="d-flex" v-if="!editModeStatus">
                <span class="font-weight-bold" :class="handleColorStatusOrder(
                    (OrderModule.order.status) ? OrderModule.order.status.ext_id : null
                  )">
                  {{ (OrderModule.order.status) ? OrderModule.order.status.name : name }}
                </span>
                <button class="btn btn-sm btn-success ml-3" v-on:click="changeStatus($event)" v-if="this.$store.getters.userIsAdmin && !this.orderNotConformity">
                  <b-icon class="mr-1" icon="pencil-square" font-scale="1.3" variant="light"></b-icon>
                  {{ $t('common.changeThe') }} {{ $t('common.status') }}
                </button>
              </div>
              <div class="d-flex" v-else>
                <select id="select-status" class="form-control" v-model="selectStatus" @input="changeStatus">
                  <option v-for="(status, index) in OrderModule.orderStatus" :key="index" :value="status.id">
                    {{ status.name }}
                  </option>
                </select>
                <button class="btn btn-sm btn-success ml-3" v-on:click="updateStatus">
                  {{ $t('common.validate') }}
                </button>
                <button class="btn btn-sm btn-warning ml-1" v-on:click="cancelStatus">
                  {{ $t('common.cancel') }}
                </button>
              </div>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>Total</strong></span>
              <span>{{ OrderModule.order.total }} €</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.resalePrice') }}</strong></span>
              <span>{{ OrderModule.order.resalePrice }} €</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.weight') }}</strong></span>
              <span>{{ OrderModule.order.weight }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>Date de création</strong></span>
              <span>{{ OrderModule.order.createdAt|moment('DD/MM/Y') }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>Date d'expédition estimée</strong></span>
              <span>{{ calculatedDate(order)|moment('DD/MM/Y') }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.shape')}}</strong></span>
              <span v-if="OrderModule.order.quote.shape">{{ OrderModule.order.quote.shape.name }}</span>
            </div>
          </div>
        </div>

        {{/* Reseller */}}
        <div class="card border-0 shadow p-3">
          <div class="card-header">
            <h4>{{ $tc('common.reseller', 0) }}</h4>
          </div>

          <div class="card-body">
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.company') }}</strong></span>
              <span>{{ (OrderModule.order.frontUser) ? OrderModule.order.frontUser.company : 'aucun' }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.mailReseller') }}</strong></span>
              <span>{{ (OrderModule.order.frontUser) ? OrderModule.order.frontUser.username : 'aucun' }}</span>
            </div>
          </div>
        </div>

        {{/* Utilisateur */}}
        <div class="card border-0 shadow p-3" v-if="OrderModule.order.frontUser">
          <div class="card-header">
            <h4>{{ $tc('common.user', 0) }}</h4>
          </div>

          <div class="card-body">
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.lastname') }}</strong></span>
              <span>{{ (OrderModule.order.frontUser) ? OrderModule.order.frontUser.lastname : null }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.firstname') }}</strong></span>
              <span>{{ (OrderModule.order.frontUser) ? OrderModule.order.frontUser.firstname : null }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>Mail</strong></span>
              <span>{{ (OrderModule.order.frontUser) ? OrderModule.order.frontUser.username : null }}</span>
            </div>
          </div>
        </div>

        {{/* PDF */}}
        <div v-if="!$store.getters.userIsReseller" class="card border-0 shadow p-3">
          <div class="card-header">
            <h4>{{ $t('pages.order.id.form.manufacturingPdf') }}</h4>
          </div>

          <div class="card-body">
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('pages.order.id.form.manufacturingPdf') }}</strong></span>
              <a :href="OrderModule.order.pdfManufacturingUrl" target="_blank">{{ $t('common.openFile') }}</a>
            </div>
          </div>
        </div>

        {{/* Quote */}}
        <div class="card border-0 shadow p-3">
          <div class="card-header">
            <h4>{{ $tc('common.quote', 0) }}</h4>
          </div>

          <div class="card-body" v-if="OrderModule.order.quote">
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $tc('common.quote', 0) }}</strong></span>
              <b-list-group class="w-50">
                <b-list-group-item class="text-secondary">
                  #ID : {{ OrderModule.order.quote.id }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.reference') }} : {{ OrderModule.order.quote.reference }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.price') }} : {{ OrderModule.order.quote.price }} €
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $tc('common.scenario', 0) }} : {{ OrderModule.order.scenario.name }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.weight') }} : {{ OrderModule.order.weight }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.width') }} : {{ OrderModule.order.quote.width }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.height') }} : {{ OrderModule.order.quote.height }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.diameter') }} : {{ OrderModule.order.quote.diameter }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.application') }} : {{ OrderModule.order.quote.application }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.mandrel') }} : {{ OrderModule.order.quote.coreSize }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.qtyPerRoll') }} : {{ OrderModule.order.quote.quantityPerRoll }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  {{ $t('common.qtyPerSeries') }} : {{ OrderModule.order.quote.quantityPerSeries }}
                </b-list-group-item>
                <b-list-group-item class="text-secondary">
                  <b-list-group-item class="text-secondary" v-for="(serie, index) in OrderModule.order.quote.series" :key="index">
                    <a :href="serie.file" target="_blank">{{ $t('common.openFile') }}</a>
                  </b-list-group-item>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>
      </div>

      {{/* Left side */}}
      <div class="col-4 p-0">
        {{/* Final client */}}
        <div class="card border-0 shadow p-3">
          <div class="card-header">
            <h4>{{ $t('pages.order.id.form.endCustomer') }}</h4>
          </div>

          <div class="card-body" v-if="OrderModule.order.customer">
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.customer') }}</strong></span>
              <span>{{ OrderModule.order.customer.name }} {{ OrderModule.order.customer.firstname }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.address') }}</strong></span>
              <span>{{ OrderModule.order.address.address }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.secondAddress') }}</strong></span>
              <span v-if="OrderModule.order.address.address2 && OrderModule.order.address.address2 !== ' '">
                {{ OrderModule.order.address.address2 }}
              </span>
              <b-badge class="ml-2" v-else variant="secondary">{{ $t('common.none') }}</b-badge>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.thirdAddress') }}</strong></span>
              <span v-if="OrderModule.order.address.address3 && OrderModule.order.address.address3 !== ' '">
                {{ OrderModule.order.address.address3 }}
              </span>
              <b-badge class="ml-2" v-else variant="secondary">{{ $t('common.none') }}</b-badge>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.mail') }}</strong></span>
              <span v-if="OrderModule.order.customer.mail">
                {{ OrderModule.order.customer.mail }}
              </span>
              <b-badge class="ml-2" v-else variant="secondary">{{ $t('common.none') }}</b-badge>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.phone') }}</strong></span>
              <span v-if="OrderModule.order.customer.phone">
                {{ OrderModule.order.customer.phone }}
              </span>
              <b-badge class="ml-2" v-else variant="secondary">{{ $t('common.none') }}</b-badge>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.zipCode') }}</strong></span>
              <span>{{ OrderModule.order.address.postalCode }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.city') }}</strong></span>
              <span>{{ OrderModule.order.address.city }}</span>
            </div>
            <div class="d-flex mb-3">
              <span class="w-25"><strong>{{ $t('common.country') }}</strong></span>
              <span>{{ OrderModule.order.address.country }}</span>
            </div>
          </div>
        </div>
        {{/* Tracking */}}
        <div class="card border-0 shadow p-3">
          <div class="card-header">
            <h4>{{ $t('pages.order.id.form.packaging') }}</h4>
          </div>

          <div class="card-body">
            <div class="card-body">
              <div class="d-flex">
                <span><strong>{{ $t('pages.order.id.form.deliveryLabel') }} : </strong></span>
                <span v-if="OrderModule.order.shippingLabelle">{{ OrderModule.order.shippingLabelle }}</span>
                <b-badge class="ml-2" v-else variant="secondary">{{ $t('common.none') }}</b-badge>
              </div>
            </div>

            <hr />

            <div v-for="(tracking, index) in OrderModule.order.tracking" :key="index">
              <div class="card mt-2">
                <div class="card-body">
                  <div class="d-flex mb-3">
                    <span class="w-25"><strong>{{ $t('common.reference') }}</strong></span>
                    <span>{{ tracking.reference }}</span>
                  </div>
                  <div class="d-flex mb-3">
                    <span class="w-25"><strong>{{ $t('common.carrier') }}</strong></span>
                    <span>{{ tracking.carrier }}</span>
                  </div>
                  <div class="d-flex mb-3">
                    <span class="w-25"><strong>{{ $t('pages.order.id.form.method') }}</strong></span>
                    <span>{{ tracking.method }}</span>
                  </div>
                  <div class="d-flex mb-3">
                    <span class="w-25"><strong>{{ $t('common.weight') }}</strong></span>
                    <span>{{ tracking.weight }}</span>
                  </div>
                  <div class="d-flex mb-3">
                    <span class="w-25"><strong>Url</strong></span>
                    <span>
                      <a :href="tracking.url">{{ tracking.url }}</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {{/* NOT CONFORMITY */}}
        <div class="card border-0 shadow p-3" v-if="this.$store.getters.userIsAdmin">
          <div class="card-header">
            <h4 class="text-danger">{{ $t('pages.order.id.form.orderNotCompliant') }}</h4>
          </div>
          <div class="card-body">
            <div v-if="!orderNotConformity">
              <button
                  class="btn btn-outline-danger mr-2"
                  v-on:click="openModalNotConformity"
              >
                {{ $t('pages.order.id.form.requestOrderNotCompliant') }}
              </button>
            </div>
            <div v-else>
              <p>
                {{ $t('pages.order.id.form.requestMadeOn') }} : <b>{{ orderNotConformity.createdAt |moment('DD/MM/YYYY') }}</b>
              </p>
              <p>
                {{ $t('common.yourComment') }} : <b>{{ orderNotConformity.commentAdmin }}</b>
              </p>
              <p>
                {{ $t('pages.order.id.form.resellerAnswer') }} :
                <b>
                  {{
                    (orderNotConformity.responseReseller)
                    ? (orderNotConformity.responseReseller === 'print-anyway')
                            ? $t('pages.order.id.form.printAnyway')
                            : $t('pages.order.id.form.sendNewFile')
                    : $t('common.waiting')
                  }}
                </b>
              </p>
              <p v-if="orderNotConformity.responseReseller">
                {{ $t('pages.order.id.form.resellerComment') }} :
                <b>{{ (orderNotConformity.commentReseller) ? orderNotConformity.commentReseller : $t('common.noComment') }}</b>
              </p>
            </div>
          </div>
        </div>
        {{/* Delivery note */}}
        <div class="card border-0 shadow p-3">
          <div class="card-header">
            <h4>{{ $t('pages.order.deliveryNote') }}</h4>
          </div>

          <div class="card-body">
            <div class="d-flex mb-3" v-if="OrderModule.order.deliveryNoteUrl">
              <a :href="OrderModule.order.deliveryNoteUrl" target="_blank">{{ $t('common.openFile') }}</a>
            </div>
          </div>
          <input
              class="btn btn-sm btn-primary"
              type="file"
              accept="application/pdf"
              @change="handleDeliveryNotUpload($event, OrderModule.order)"
          >
        </div>
      </div>
    </div>

    {{/* Modal Cancel Order */}}
    <div class="div-modal">
      <b-modal
          :key="100"
          id="modal-order-not-conformity"
          ref="modal"
          size="xl"
          centered
          :title="$t('pages.order.modalNotConformity.title')"
          v-model="modal.open"
          @hidden="resetModal"
          @ok="changeOrderStatusNotConformity"
          header-bg-variant="danger"
          header-text-variant="light"
          body-bg-variant="light"
          footer-bg-variant="light"
      >
        <div class="order-data mb-2">
          <h4>{{ $t('pages.order.modalNotConformity.listFiles') }} : </h4>

          <div class="" v-if="OrderModule.order.quote">
            <div class="col-12" v-for="(serie, index) in OrderModule.order.quote.series" :key="index">
              <div class="card d-flex flex-row justify-content-between p-2 mb-2">
                <div>
                  <span class="font-weight-bold">{{ $t('common.name') }} : </span>
                  <span class="text-info font-weight-bold">{{ serie.name }}</span>
                </div>
                <div>
                  <span class="font-weight-bold">{{ $t('common.link') }} (s3): </span>
                  <a :href="serie.file" target="_blank">{{ $t('common.openFile') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <form ref="form">
          <div class="form-group">
            <label for="order-not-conformity-reason">
              {{ $t('pages.order.modalNotConformity.chooseReason') }} :
            </label>
            <select class="form-control" id="order-not-conformity-reason" v-model="form.reason">
              <option :value="reason.slug" v-for="(reason, index) in this.listReason" :key="index">
                {{ reason.label }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="order-not-conformity-comment">{{ $t('common.yourComment') }} :</label>
            <textarea
                class="form-control"
                id="order-not-conformity-comment"
                v-model="form.commentAdmin"
                :placeholder="$t('common.yourComment')"
            >
            </textarea>
          </div>
        </form>

        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="secondary" @click="cancel()">
            {{ $t('common.cancel') }}
          </b-button>
          <b-button size="sm" variant="success" @click="ok()">
            {{ $t('pages.order.modalNotConformity.validate') }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import {ORDER_STATUS_EXT_IDS} from "@/utils/OrderStatusExtIds";
import OrderService from "@/services/OrderService";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      listReason: [
        { slug: 'low-resolution', label: this.handleTranslate('pages.order.modalNotConformity.lowResolution')},
        { slug: 'file-not-conforming', label: this.handleTranslate('pages.order.modalNotConformity.notConforming')}
      ],
      order: null,
      modal: { open: false },
      form: {
        reason: null,
        commentAdmin: null,
        userId: null,
        resellerId: null,
        orderId: null
      },
      orderNotConformity: false,
      editModeStatus: false,
      selectStatus: null
    }
  },
  async created() {
    this.showLoader()

    this.$store.dispatch('fetchOrderRequestNotConformity', {orderId: this.$route.params.id, orderStatusExtId: ORDER_STATUS_EXT_IDS.NOT_CONFORMED}).then((response) => {
      // Check if request order not conformity is already exist
      this.orderNotConformity = response
    })

    this.selectStatus = (this.OrderModule.order && this.OrderModule.order.status) ? this.OrderModule.order.status.id : null
    // Fetch order
    this.$store.dispatch('fetchOrder', this.$route.params.id)
      .then(() => {
        this.hideLoader()
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FORMAT ------------------*/
    calculatedDate(order) {
      if (order) {
        let delay = this.getDelayOnCategory(order)
        return moment(order.createdAt, "YYYY-MM-DD").add(delay, 'days')
      }
    },
    getDelayOnCategory(order) {
      let delay = null
      if (order.scenario) {
        order.scenario.categoriesScenarios.forEach((item) => {
          if(item.category.pose.shortCode === order.quote.application) {
            delay = item.delay
          }
        })
      }

      return delay
    },
    handleTranslate(indexTrad)
    {
      return this.$t(indexTrad)
    },
    handleTranslatePlural(indexTrad, indexPlural)
    {
      return this.$tc(indexTrad, indexPlural)
    },
    /*----------------- MODAL ------------------*/
    openModalNotConformity() {
      this.modal.open = true
    },
    resetModal()
    {
      this.modal.open = false
      this.form.reason = null
      this.form.commentAdmin = null
    },
    /*----------------- CHANGE STATUS ------------------*/
    async changeOrderStatusNotConformity() {
      // Loader
      this.showLoader()

      if (this.OrderModule.order.user.roles[0] === 'ROLE_ADMIN') {
        this.$bvToast.toast(this.handleTranslate('pages.order.toast.chooseMadeByReseller'), {
          title: this.handleTranslate('pages.order.toast.placedByAdmin'),
          variant: 'danger',
          solid: true
        })
      } else if (this.OrderModule.order.user.roles[0] === 'ROLE_RESELLER') {
        // Set var for from
        this.form.userId = this.$store.getters.getCurrentUser.id
        this.form.resellerId = (this.OrderModule.order.frontUser) ? this.OrderModule.order.frontUser.parent.id : this.OrderModule.order.user
        this.form.orderId = this.$route.params.id

        await this.$store.dispatch('createOrderRequestNotConformity', {
          id: this.$route.params.id,
          orderRequestNotConformity: this.form
        })
          .then((response) => {
            if (!response.entity) {
              this.$bvToast.toast(this.handleTranslate('pages.order.toast.errorDeletedFileFTP') + ' : ' + response.process_message, {
                title: this.handleTranslate('pages.order.toast.procedureCancelled'),
                variant: 'danger',
                solid: true
              })
            } else {
              this.$bvToast.toast(this.handleTranslate('pages.order.toast.orderPlacedNotConforming'), {
                title: this.handleTranslate('pages.order.toast.nonCompliantRequest'),
                variant: 'success',
                solid: true
              })

              this.orderNotConformity = response.entity
              this.orderNotConformity.createdAt = moment(response.createdAt).format('DD/MM/YYYY')

              // Fetch Order
              this.$store.dispatch('fetchOrder', this.$route.params.id)
              // this.$store.dispatch('fetchOrderRequestNotConformity', this.$route.params.id)
            }

            // Loader
            this.hideLoader()
          }).catch(() => {
            this.hideLoader()
          })
      }
    },
    changeStatus()
    {
      this.editModeStatus = true
    },
    updateStatus()
    {
      this.$swal({
        title: this.handleTranslate('common.changeStatus') + ' !',
        text: this.handleTranslate('pages.order.toast.askChangeStatus') + ' ' + this.OrderModule.order.id,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.validate'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if(result.value) {
          // Loader
          this.showLoader()

          // CALL API
          this.$store.dispatch('updateStatus', {id: this.OrderModule.order.id, status: this.selectStatus})
            .then(() => {
              this.hideLoader()

              this.$bvToast.toast(this.handleTranslate('common.changeStatus'), {
                title: this.handleTranslatePlural('common.changeStatusSuccess', 0),
                variant: 'success',
                solid: true
              })

              this.editModeStatus = false
              this.orderNotConformity = null
              this.selectStatus = 8
            })
            .catch((error) => {
              console.log(error.message)
              this.hideLoader()
            })
        }
      })
    },
    cancelStatus()
    {
      this.editModeStatus = false
      this.selectStatus = (this.OrderModule.order && this.OrderModule.order.status) ? this.OrderModule.order.status.id : null
    },
    handleColorStatusOrder(extId) {
      switch (extId) {
        case 0:
          return 'text-warning'
        case 1:
          return 'text-info'
        case 2:
          return 'text-dark'
        case 3:
          return 'text-primary'
        case 4:
          return 'text-warning'
        case 5:
          return 'text-not-conformity'
        case 99:
          return 'text-success'
        case 100:
          return 'text-danger'
      }
    },
    async handleDeliveryNotUpload($event, order)
    {
      //let fileModel = await this.handleFileToBase64($event.target.files)

      let formData = new FormData();
      formData.append('file', $event.target.files[0]);
      formData.append('id', order.id);

      await this.$swal({
        title: this.$t('common.uploadFile'),
        text: this.$t('common.validUpload'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.validate'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          this.showLoader()

          OrderService.addDeliveryNote(order.id, formData)
              .then((response) => {
                this.$bvToast.toast(this.$t('pages.orderNotConformity.toast.newFile'), {
                  title: this.$t('pages.orderNotConformity.toast.downloadNewFile'),
                  variant: 'success',
                  solid: true
                })

                location.reload()

                // Set new file
                this.response = response.data

                this.haveFilesAddedButNotSubmitted = true;
                //this.hideLoader()
              })
              .catch(() => {
                this.hideLoader()
              })
        }
      })
    },
  },
  computed: {
    ...mapState(['OrderModule']),
    ...mapState(['OrderRequestNotConformityModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
