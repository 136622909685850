<template>
  <div id="page-category-scenario-edit">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <div>
        <h5>
          {{ $t('pages.categoryScenario.editCategoryScenario') }}:
          <strong>{{ CategoryScenarioModule.categoryScenario.category.name }} | {{ CategoryScenarioModule.categoryScenario.scenario.name }}</strong>
        </h5>
      </div>
      <div>
        <router-link
            class="btn btn-outline-primary mb-3"
            :to="{ name: 'cat-sc-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudCategoryScenario
        :category-scenario="CategoryScenarioModule.categoryScenario"
        :id="CategoryScenarioModule.categoryScenario.id"
        :actionForm="'edit'"
        :selected-category="getSelectedCategory"
        :selected-scenario="getSelectedScenario"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchCategoryScenario', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    getSelectedCategory() {
      return (this.CategoryScenarioModule.categoryScenario && this.CategoryScenarioModule.categoryScenario.category) ? this.CategoryScenarioModule.categoryScenario.category.id : null
    },
    getSelectedScenario() {
      return (this.CategoryScenarioModule.categoryScenario && this.CategoryScenarioModule.categoryScenario.scenario) ? this.CategoryScenarioModule.categoryScenario.scenario.id : null
    },
    ...mapState(['CategoryScenarioModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    FormCrudCategoryScenario: () => import('@/components/pages/categoryScenario/FormCrudCategoryScenario')
  }
}
</script>

<style scoped>

</style>
