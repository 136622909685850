import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of reseller
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getResellers(perPage, page, order, search) {
    let url = 'admin/resellers'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  getSearchResellers(search) {
    return AxiosService.callApi().get('admin/resellers/search?_search=' + search)
  },
  /**
   * Get reseller by id
   *
   * @param id
   * @returns {*}
   */
  getReseller(id) {
    return AxiosService.callApi().get('/admin/resellers/' + id)
  },
  /**
   * Create Reseller
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/resellers', data)
  },
  /**
   * Edit Reseller
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/resellers/' + id, data)
  },
  /**
   * Edit Reseller is Active
   *
   * @param id
   * @param data
   * @returns {*}
   */
  editIsActive(id, data) {
    return AxiosService.callApi().put('/admin/resellers/active/' + id, data)
  },
  /**
   * Delete Reseller
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/resellers/' + id)
  }
}
