import OrderNotConformityService from '@/services/OrderNotConformityService'

export default {
  state: {
    requestNotConformityList: [],
    requestNotConformity: {},
    requestNotConformityNotResponded: null,
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_REQUEST_NOT_CONFORMITY_LIST(state, requestNotConformity) {
      state.requestNotConformityList.push(requestNotConformity)
    },
    SET_REQUEST_NOT_CONFORMITY_LIST(state, requestNotConformity) {
      state.requestNotConformityList = requestNotConformity
    },
    SET_REQUEST_NOT_CONFORMITY(state, requestNotConformity) {
      state.requestNotConformity = requestNotConformity
    },
    SET_COUNT_NOT_CONFORMITY_NOT_RESPONDED(state, requestNotConformityNotResponded) {
      state.requestNotConformityNotResponded = requestNotConformityNotResponded
    },
    SET_REQUEST_NOT_CONFORMITY_TOTAL(state, total) {
      state.total = total
    },
    SET_REQUEST_CONFORMITY_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_REQUEST_CANCEL(state, requestCancel) {
      state.requestCancel = requestCancel
    },
  },
  actions: {
    /**
     * Fetch in state list of order request not conformities
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     * @param resellerId
     */
    fetchOrderRequestNotConformityList({ commit }, { perPage, page, order, search, resellerId }) {
      return OrderNotConformityService.getOrderNotConformityList(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null,
        resellerId
      )
        .then(response => {
          commit('SET_REQUEST_NOT_CONFORMITY_LIST', response.data.list)
          commit('SET_REQUEST_NOT_CONFORMITY_TOTAL', response.data.total)
          commit('SET_REQUEST_CONFORMITY_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on order request not conformities list: ', error)
        })
    },
    countOrderRequestNotConformityList({ commit }, { resellerId }) {
      return OrderNotConformityService.countOrderRequestNotConformityNotResponded(resellerId)
        .then(response => {
          commit('SET_COUNT_NOT_CONFORMITY_NOT_RESPONDED', response.data)

          return Promise.resolve(response.data)
        })
    },
    /**
     * Get request not conformity by order ID
     * @param commit
     * @param orderId
     * @param orderStatusExtId
     * @param status
     */
    fetchOrderRequestNotConformity({ commit }, {orderId, orderStatusExtId}) {
      return OrderNotConformityService.getOrderNotConformityByOrderId(orderId, orderStatusExtId)
        .then(response => {
          commit('SET_REQUEST_NOT_CONFORMITY', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on order request not conformity by order ID : ', error)
        })
    },
    /**
     * create order request not conformity
     *
     * @param commit
     * @param id
     * @param orderRequestNotConformity
     * @param respond
     * @returns {*}
     */
    createOrderRequestNotConformity({ commit }, { orderRequestNotConformity })
    {
      return OrderNotConformityService.createOrderNotConformity(orderRequestNotConformity)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_REQUEST_NOT_CONFORMITY', response.data)

          return Promise.resolve(response.data)
        })
    },
    responseResellerOrderNotConformity({ commit }, model)
    {
      return OrderNotConformityService.responseResellerOrderNotConformity(model)
        .then((response) => {
          commit('SET_REQUEST_NOT_CONFORMITY', response.data)

          return Promise.resolve(response.data)
        })
    }
  },
  getters: {
    /**
     * Get Order not conformity by id in requestNotConformityList
     *
     * @param state
     * @returns {function(*): *}
     */
    getOrderNotConformityById: state => id => {
      return state.requestNotConformityList.find(requestNotConformity => requestNotConformity.id === id)
    },
    getOrderNotConformityByOrderIdForList: state => orderId => {
      return state.requestNotConformityList.find(requestNotConformity => requestNotConformity.order.id === orderId);
    }
  }
}
