<template>
  <div id="page-shape">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="gear" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.shape.title') }}
      </h3>
      <div>
        <router-link
            class="btn btn-info mt-2"
            :to="{ name: 'shape-create' }"
        >
          {{ $t('pages.shape.createShape') }}
        </router-link>
      </div>
    </div>

    <hr />
    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="ShapeModule.shapes.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="ShapeModule.shapes"
            :entityName="'user'"
            :actionList="configTableComponent.actionList"
            :specificAction="configTableComponent.specificAction"
            :isAction="true"
            @ordered="clickOrdered"
            @specific-action="handleSpecificAction"
        />

        <div v-if="ShapeModule.shapes.length === 0">
          <div v-if="filter.search != null" class="text-center mt-5">
            {{ $t('common.noResult') }}
          </div>
          <div v-else class="text-center mt-5">
            {{ $t('common.noResult') }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="count-elem my-auto">
            <b>{{ (filter.search) ? ShapeModule.totalRequest :  ShapeModule.total}}</b>
            {{ $t('common.result') }}
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="ShapeModule.shapes.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="(ShapeModule.totalRequest > 0) ? Math.ceil(ShapeModule.totalRequest / filter.elemPerPage) : Math.ceil(ShapeModule.total / filter.elemPerPage)"
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 10,
        order: null,
        search: null,
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList: [
          {'index': 'id', 'name': '#', 'align': 'left', 'filter': true},
          {'index': 'name', 'indexTrad': 'common.name', 'align': 'left', 'filter': true},
        ],
        actionList: [
          {
            'indexTrad': 'common.actions.edit',
            'routeName': 'shape-edit',
            'icon': 'pencil-square',
            'icon-variant': 'info',
            'params': true,
            'link-action': true
          }
        ]
      }
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchShapes', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page
    })

  },
  updated() {
    this.hideLoader()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset() {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchShapes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest() {
      this.showLoader()

      this.$store.dispatch('fetchShapes', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest() {
      this.showLoader()

      this.$store.dispatch('fetchShapes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order) {
      this.showLoader()

      this.order = order

      this.$store.dispatch('fetchShapes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SWITCH / SPECIFIC ACTION ------------------*/
    /**
     * handle specific action (emit from table component)
     *
     * @param obj
     */
    handleSpecificAction(obj) {

      // Delete
      if (obj.action === 'delete') {
        this.showLoader()

        this.$swal({
          title: this.$t('pages.shape.wantDelete'),
          text: this.$t('common.actionIrreversible'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#fa5c7c',
          cancelButtonColor: '#929292',
          confirmButtonText: this.$t('common.actions.delete')
        }).then((result) => {

          if (result.value) {
            this.$store.dispatch('deleteShape', {id: obj.id})
                .then(() => {
                  this.$bvToast.toast(this.$t('pages.shape.deleteSuccess'), {
                    variant: 'success',
                    solid: true
                  })

                  this.$store.dispatch('fetchShapes', {
                    perPage: this.filter.elemPerPage,
                    page: 1
                  })

                  this.hideLoader()
                })
                .catch((error) => {
                  this.hideLoader()

                  this.$bvToast.toast(error.message, {
                    title: this.$t('pages.shape.errorDeleteTitle'),
                    variant: 'warning',
                    solid: true
                  })
                })
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['ShapeModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>

<style scoped>

</style>
