<template>
  <div class="row h-100" id="page-forgot-password">

    <div class="col-4 m-auto">
      <h2 class="text-center mb-5">{{ $t('pages.forgotPassword.requestForgottenPassword') }}</h2>

      <div class="form-group">
        <input class="form-control" id="email" v-model="form.email" type="email" :placeholder="$t('pages.forgotPassword.yourEmail')">
      </div>

      <div class="text-center mt-3 mb-3">
        <a class="m-auto" href="/">{{ $t('pages.forgotPassword.backLoginPage') }}</a>
      </div>

      <button class="btn btn-lg btn-block btn-primary" v-on:click="requestForgotPassword">
        {{ $t('pages.forgotPassword.sendRequestForgottenPassword') }}
      </button>
    </div>
  </div>
</template>

<script>
import ResetPasswordRequestService from '@/services/ResetPasswordRequestService'

export default {
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  created() {
    this.$store.dispatch('logout', false)
  },
  methods: {
    requestForgotPassword() {
      if (!this.form.email) {
        this.$bvToast.toast(this.$t('pages.forgotPassword.errorFillEmail'), {
          title: this.$t('common.error'),
          variant: 'danger',
          solid: true
        })
      } else {
        ResetPasswordRequestService.checkUserByEmail({email: this.form.email})
            .then((response) => {
              if (response.data.isExist) {

                // Send Mail
                ResetPasswordRequestService.sendRequestForgotPassword(response.data.user.id)
                    .then(() => {
                      this.$bvToast.toast(this.$t('pages.forgotPassword.passwordRenewal'), {
                        title: this.$t('pages.forgotPassword.requestPasswordRenewal'),
                        variant: 'success',
                        solid: true
                      })
                    })

              } else {
                this.$bvToast.toast(this.$t('pages.forgotPassword.noActiveAccount') + ' : ' + this.form.email, {
                  title: this.$t('common.error'),
                  variant: 'danger',
                  solid: true
                })
              }
            })
            .catch((error) => {
              this.$bvToast.toast(error.message, {
                title: this.$t('common.error'),
                variant: 'danger',
                solid: true
              })
            })
      }
    }
  },
  computed: {}
}
</script>
