import FinishingService from '@/services/FinishingService'

export default {
  state: {
    finishing: {},
    finishes: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_FINISHING(state, finishing) {
      state.finishes.push(finishing)
    },
    SET_FINISHES(state, finishes) {
      state.finishes = finishes
    },
    SET_FINISHES_TOTAL(state, total) {
      state.total = total
    },
    SET_FINISHES_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_FINISHING(state, finishing) {
      state.finishing = finishing
    },
  },
  actions: {
    /**
     * Fetch in state list of finishes
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchFinishes({ commit }, { perPage, page, order, search }) {
      return FinishingService.getFinishes(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_FINISHES', response.data.list)
          commit('SET_FINISHES_TOTAL', response.data.total)
          commit('SET_FINISHES_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on Finishes list: ', error)
        })
    },
    /**
     * Fetch in state a finishing
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchFinishing({ commit }, id)
    {
      FinishingService.getFinishing(id)
        .then(response => {
          commit('SET_FINISHING', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Finishing : ', error)
        })
    },
    /**
     * Create Finishing
     *
     * @param commit
     * @param finishing
     */
    createFinishing({ commit }, finishing)
    {
      return FinishingService.create(finishing)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_FINISHING', finishing)
          return Promise.resolve()
        })
    },
    /**
     * Update finishing
     *
     * @param commit
     * @param id
     * @param finishing
     * @returns {*}
     */
    updateFinishing({ commit }, {id, finishing})
    {
      return FinishingService.edit(id, finishing)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_FINISHING', response.data)
        })
    },
    /**
     * Delete Finishing
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteFinishing({ commit }, {id}) {
      return FinishingService.delete(id)
    }
  },
  getters: {
    /**
     * Get Finishing by id in list finishes
     *
     * @param state
     * @returns {function(*): *}
     */
    getFinishingById: state => id => {
      return state.finishes.find(finishing => finishing.id === id)
    }
  }
}
