import ShapeService from '@/services/ShapeService'

export default {
  state: {
    shape: {},
    shapes: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_SHAPE(state, shape) {
      state.shapes.push(shape)
    },
    SET_SHAPES(state, shapes) {
      state.shapes = shapes
    },
    SET_SHAPES_TOTAL(state, total) {
      state.total = total
    },
    SET_SHAPES_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_SHAPE(state, shape) {
      state.shape = shape
    },
  },
  actions: {
    /**
     * Fetch in state list of shapes
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchShapes({ commit }, { perPage, page, order, search }) {
      return ShapeService.getShapes(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_SHAPES', response.data)


          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on shape list: ', error)
        })
    },
    /**
     * Fetch in state a shape
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchShape({ commit }, id)
    {
      ShapeService.getShape(id)
        .then(response => {
          commit('SET_SHAPE', response.data)
        })
        .catch((error) => {
          console.log('There was an error on shape : ', error)
        })
    },
    /**
     * Create shape
     *
     * @param commit
     * @param shape
     */
    createShape({ commit }, shape)
    {
      return ShapeService.create(shape)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_SHAPE', shape)
          return Promise.resolve()
        })
    },
    /**
     * Update shape
     *
     * @param commit
     * @param id
     * @param shape
     * @returns {*}
     */
    updateShape({ commit }, {id, shape})
    {
      return ShapeService.edit(id, shape)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_SHAPE', response.data)
        })
    },

    // eslint-disable-next-line no-unused-vars
    deleteShape({ commit }, {id}) {
      return ShapeService.delete(id)
    }
  },
  getters: {
    /**
     * Get shape by id in list shapes
     *
     * @param state
     * @returns {function(*): *}
     */
    getShapeById: state => id => {
      return state.shapes.find(shape => shape.id === id)
    }
  }
}
