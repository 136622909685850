<template>
  <div id="page-cms-calculator">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="collection" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.cms.title') }}
      </h3>
    </div>

    <hr />

    <div class="mt-4">
      {{/* ADD TOOLTIP */}}
      <div class="w-100 d-flex justify-content-between mb-2">
        <h5>{{ $t('pages.cms.managementTooltip') }}</h5>
        <button class="btn btn-success" v-on:click="dataPage.crudMode = 'created'">
          {{ $t('pages.cms.createTooltip') }}
        </button>
      </div>
      {{/* CREATED TOOLTIP BLOCK */}}
      <div class="w-100 d-flex justify-content-end">
        <div class="card col-5" v-if="dataPage.crudMode === 'created'">
          <div class="card-header">
            <h5 class="p-0">
              <b-icon class="mr-3" icon="hdd" font-scale="1.5" variant="primary"></b-icon>
              {{ $t('pages.cms.createTooltip') }}
            </h5>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="select-category">{{ $t('pages.cms.labelCreateInputName') }} *</label>
              <select id="select-category" class="form-control" v-model="cmsCalculatorEntry.targetType">
                <template v-for="(input, index) in listInputs">
                  <option :value="input.slug" :key="index" v-if="!input.isCreated">
                    {{ input.name }}
                  </option>
                </template>
              </select>
            </div>

            <div class="form-group">
              <label for="tooltip-text">{{ $t('pages.cms.text') }} *</label>
              <textarea class="form-control" id="tooltip-text" v-model="cmsCalculatorEntry.value" :placeholder="$t('pages.cms.yourText')">
              </textarea>
            </div>
          </div>
          <div class="d-flex justify-content-end pr-3 pb-2">
            <button class="btn btn-success" v-on:click="onSubmit">{{ $t('pages.cms.createTooltip') }}</button>
            <button class="btn btn-warning ml-2" v-on:click="dataPage.crudMode = false">{{ $t('common.cancel') }}</button>
          </div>
        </div>
      </div>

      <div class="card table-card border-0 shadow">
        <div class="card-body p-0">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('pages.cms.inputName') }}</th>
              <th>{{ $t('pages.cms.inputText') }}</th>
              <th class="text-center">{{ $t('pages.cms.actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(tooltip, index) in listTooltips" :key="index">
              <td>{{ tooltip.id }}</td>
              <td>{{ handleTooltipType(tooltip.targetType) }}</td>
              <td>
                <div v-if="dataPage.crudMode === 'edited' && dataPage.tooltipSelected.id === tooltip.id">
                  <textarea class="form-control" id="tooltip-text-edit" v-model="tooltip.value" :placeholder="$t('pages.cms.yourText')">
                  </textarea>
                </div>
                <div v-else>
                  {{ tooltip.value }}
                </div>
              </td>
              <td class="text-center">
                <div class="d-flex justify-content-center">
                  <div class="" v-if="dataPage.crudMode === 'edited' && dataPage.tooltipSelected.id === tooltip.id">
                    <button class="btn btn-sm btn-success" v-on:click="onSubmitEdit(tooltip)">
                      {{ $t('common.actions.edit') }}
                    </button>
                    <button class="btn btn-sm btn-warning ml-2" v-on:click="onEditCancel(tooltip)">
                      {{ $t('common.cancel') }}
                    </button>
                  </div>
                  <div v-else>
                    <button class="btn btn-sm btn-success" v-on:click="handleEditMode(tooltip)">
                      <b-icon class="h-25" icon="pencil-square" font-scale="1" variant="light"></b-icon>
                    </button>
                  </div>

                  <div>
                    <button class="btn btn-sm btn-danger ml-2" v-on:click="onDelete(tooltip)">
                      <b-icon class="h-25"  icon="trash2" font-scale="1" variant="light"></b-icon>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      dataPage: {
        title: 'CMS calculateur',
        crudMode: false,
        tooltipSelected: false
      },
      listTooltips: [],
      listInputs: [
        { name: this.$t('common.mandrel'), slug: 'mandrel', isCreated: false },
        { name: this.$t('common.paper'), slug: 'papers', isCreated: false },
        { name: this.$t('common.finishing'), slug: 'finishing', isCreated: false },
        { name: this.$t('pages.cms.labelsPerRoll'), slug: 'rolls', isCreated: false },
        { name: this.$t('common.width'), slug: 'width', isCreated: false },
        { name: this.$t('common.height'), slug: 'height', isCreated: false }
      ],
      cmsCalculatorEntry: {
        targetType: null,
        value: null,
        cmsType: 'tooltip'
      }
    }
  },
  created() {
    this.showLoader()

    this.fetchTooltips()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader()
    {
      this.loader.isLoading = true
    },
    hideLoader()
    {
      this.loader.isLoading = false
    },
    /*----------------- FETCH METHOD ------------------*/
    fetchTooltips() {
      this.$store.dispatch('fetchCmsCalculatorEntries', 'tooltip')
        .then((response) => {
          this.listTooltips = response

          // Handle in list inputs
          this.listTooltips.forEach((tooltip) => {
            // Remove from listInputs
            this.listInputs.find(input => input.slug === tooltip.targetType).isCreated = true
          })

          this.hideLoader()
        })
        .catch(() => {
          this.hideLoader()
        })
    },
    /*----------------- FORMAT METHOD ------------------*/
    handleTooltipType(type)
    {
      return this.listInputs.find(input => input.slug === type).name
    },
    /*----------------- SUBMIT FORM ------------------*/
    onSubmit()
    {
      this.$store.dispatch('createCmsCalculatorEntry', this.cmsCalculatorEntry)
        .then((response) => {
          this.$bvToast.toast(this.$t('pages.cms.toast.createSuccess'), {
            title: this.$t('pages.cms.toast.createSuccessTitle'),
            variant: 'success',
            solid: true
          })

          // Remove from listInputs
          this.listInputs.find(input => input.slug === response.targetType).isCreated = true

          // Re-call list
          this.fetchTooltips()
        })
    },
    onSubmitEdit(tooltip)
    {
      this.$store.dispatch('updateCmsCalculatorEntry', {id: tooltip.id, cmsCalculatorEntry: tooltip})
        .then(() => {
          this.$bvToast.toast(this.$t('pages.cms.toast.editSuccess'), {
            title: this.$t('pages.cms.toast.editSuccessTitle'),
            variant: 'success',
            solid: true
          })

          // Reset VAR
          this.dataPage.tooltipSelected = {}
          this.dataPage.crudMode = null

          // re-call list
          this.fetchTooltips()
        })
    },
    /*----------------- EDIT MODE ------------------*/
    handleEditMode(tooltip) {
      this.dataPage.tooltipSelected = tooltip
      this.dataPage.crudMode = 'edited'
    },
    onEditCancel() {
      this.dataPage.tooltipSelected = null
      this.dataPage.crudMode = null
    },
    /*----------------- DELETE ------------------*/
    onDelete(tooltipObj)
    {
      this.$swal({
        title: this.$t('pages.cms.toast.wantDelete'),
        text: this.$t('common.actionIrreversible'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#fa5c7c',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.actions.delete'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('deleteCmsCalculatorEntry', tooltipObj.id)
            .then(() => {
              this.$bvToast.toast(this.$t('pages.cms.toast.deleteSuccess'), {
                title: this.$t('pages.cms.toast.deleteSuccessTitle'),
                variant: 'danger',
                solid: true
              })

              // Remove from listInputs
              this.listInputs.find(input => input.slug === tooltipObj.targetType).isCreated = false

              // re-call list
              this.fetchTooltips()
            })
        }
      })
    },
  },
  computed: {
    ...mapState(['CmsCalculatorModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
