import CategoryService from '@/services/CategoryService'

export default {
  state: {
    category: {},
    categories: [],
    poses: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_CATEGORY(state, category) {
      state.categories.push(category)
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_POSES(state, poses) {
      state.poses = poses
    },
    SET_CATEGORIES_TOTAL(state, total) {
      state.total = total
    },
    SET_CATEGORIES_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_CATEGORY(state, category) {
      state.category = category
    },
  },
  actions: {
    /**
     * Fetch in state list of categories
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchCategories({ commit }, { perPage, page, order, search }) {
      return CategoryService.getCategories(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_CATEGORIES', response.data.list)
          commit('SET_CATEGORIES_TOTAL', response.data.total)
          commit('SET_CATEGORIES_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on categories list: ', error)
        })
    },
    fetchPoses({ commit }) {
      return CategoryService.getPoses()
        .then(response => {
            commit('SET_POSES', response.data)

            return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on poses list: ', error)
        })
    },
    /**
     * Fetch in state a category
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCategory({ commit }, id)
    {
      CategoryService.getCategory(id)
        .then(response => {
          commit('SET_CATEGORY', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Category : ', error)
        })
    },
    /**
     * Create Category
     *
     * @param commit
     * @param category
     */
    createCategory({ commit }, category)
    {
      return CategoryService.create(category)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_CATEGORY', category)
          return Promise.resolve()
        })
    },
    /**
     * Update Category
     *
     * @param commit
     * @param id
     * @param category
     * @returns {*}
     */
    updateCategory({ commit }, {id, category})
    {
      return CategoryService.edit(id, category)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_CATEGORY', response.data)
        })
    },
    /**
     * Delete Category
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteCategory({ commit }, {id}) {
      return CategoryService.delete(id)
    }
  },
  getters: {
    /**
     * Get Category by id in list categories
     *
     * @param state
     * @returns {function(*): *}
     */
    getCategoryById: state => id => {
      return state.categories.find(category => category.id === id)
    }
  }
}
