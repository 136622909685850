import MandrelService from '@/services/MandrelService'

export default {
  state: {
    mandrel: {},
    mandrels: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_MANDREL(state, mandrel) {
      state.mandrels.push(mandrel)
    },
    SET_MANDRELS(state, mandrels) {
      state.mandrels = mandrels
    },
    SET_MANDRELS_TOTAL(state, total) {
      state.total = total
    },
    SET_MANDRELS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_MANDREL(state, mandrel) {
      state.mandrel = mandrel
    },
  },
  actions: {
    /**
     * Fetch in state list of mandrels
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchMandrels({ commit }, { perPage, page, order, search }) {
      return MandrelService.getMandrels(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_MANDRELS', response.data.list)
          commit('SET_MANDRELS_TOTAL', response.data.total)
          commit('SET_MANDRELS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on mandrel list: ', error)
        })
    },
    /**
     * Fetch in state a mandrel
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchMandrel({ commit }, id)
    {
      MandrelService.getMandrel(id)
        .then(response => {
          commit('SET_MANDREL', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Mandrel : ', error)
        })
    },
    /**
     * Create Mandrel
     *
     * @param commit
     * @param mandrel
     */
    createMandrel({ commit }, mandrel)
    {
      return MandrelService.create(mandrel)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_MANDREL', mandrel)
          return Promise.resolve()
        })
    },
    /**
     * Update Mandrel
     *
     * @param commit
     * @param id
     * @param mandrel
     * @returns {*}
     */
    updateMandrel({ commit }, {id, mandrel})
    {
      return MandrelService.edit(id, mandrel)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_MANDREL', response.data)
        })
    },
    /**
     * Delete Mandrel
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteMandrel({ commit }, {id}) {
      return MandrelService.delete(id)
    }
  },
  getters: {
    /**
     * Get Mandrel by id in list mandrels
     *
     * @param state
     * @returns {function(*): *}
     */
    getMandrelById: state => id => {
      return state.mandrels.find(mandrel => mandrel.id === id)
    }
  }
}
