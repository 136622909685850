<template>
  <div id="page-user-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.user.createUser') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'user-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudUser
        :user="user"
        :actionForm="'create'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataPage: {
        title: (this.$store.getters.userIsAdmin) ? this.$tc('common.user', 0) : this.$t('pages.user.userQuote')
      },
      user: {
        isActive: true,
        parent: (this.$store.getters.userIsAdmin) ? null : this.$store.getters.getCurrentUser.id,
        roles: (this.$store.getters.userIsAdmin) ? [] : ['ROLE_RESELLER_FRONT']
      }
    }
  },
  components : {
    FormCrudUser: () => import('@/components/pages/user/FormCrudUser')
  }
}
</script>

<style scoped>

</style>
