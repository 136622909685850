import AxiosService from '@/services/AxiosServices'

export default {
    addFile(id, model) {
        return AxiosService.callApi().post('/quote_series/' + id + '/file', model, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

}
