import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of Categories
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getCategories(perPage, page, order, search) {
    let url = 'admin/categories'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  /**
   * Get list of poses
   *
   * @returns {*}
   */
  getPoses() {
    return AxiosService.callApi().get('/admin/categories/poses')
  },
  /**
   * Get Category by id
   *
   * @param id
   * @returns {*}
   */
  getCategory(id) {
    return AxiosService.callApi().get('/admin/categories/' + id)
  },
  /**
   * Create Category
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/categories', data)
  },
  /**
   * Edit Category
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/categories/' + id, data)
  },
  /**
   * Delete Category
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/categories/' + id)
  }
}
