import AxiosService from '@/services/AxiosServices'

export default {
  
  getMargePrices() {
    return AxiosService.callApi().get('/reseller_marge_prices')
  },
  
  importMargePrices(data) {
    return AxiosService.callApi().post('/reseller_marge_prices', data)
  },
 
}
