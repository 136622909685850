import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// BS
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// SWEET ALERT
import VueSweetalert2 from 'vue-sweetalert2'
// LOADER CSS
import 'vue-loading-overlay/dist/vue-loading.css'
// SWEET ALERT CSS
import 'sweetalert2/dist/sweetalert2.min.css'
// APEX CHARTS
import VueApexCharts from 'vue-apexcharts'
// Vue i18n
import VueI18n from 'vue-i18n'
// Locale files JSON
import FrJson from '../src/locales/fr.json'
import EnJson from '../src/locales/en.json'
import EsJson from '../src/locales/es.json'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Sweet alert
Vue.use(VueSweetalert2)
// Apex charts
Vue.use(VueApexCharts)
// Vuei18n
Vue.use(VueI18n)

// CSS
require('../assets/scss/main.scss')

// Moment
const moment = require('moment')
require('moment/locale/fr')

Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fr',
  messages: {
    "fr": FrJson,
    "en": EnJson,
    "es": EsJson
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
