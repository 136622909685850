import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get Counters
   *
   * @returns {*}
   */
  getCounters(data) {
    let url = '/admin/dashboard/counters'
    // Reseller
    if(data.resellerId !== null)
      url += '?_resellerId=' + data.resellerId

    let dates = {
      startDate: data.startDate,
      endDate: data.endDate
    }

    return AxiosService.callApi().post(url, dates)
  },
  /**
   * Get data for chats
   *
   * @returns {*}
   */
  getCharts(data) {
    return AxiosService.callApi().post('/admin/dashboard/charts', data)
  },
  getCumulatedTurnoverChart(data) {
    return AxiosService.callApi().post('/admin/dashboard/charts/cumulated-turnover', data)
  },
}
