<template>
  <div class="page-user-show">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <div>
        <h5>
          {{ $tc('common.detailsOf', 1) }} {{ $tc('common.reseller', 0) }}:
          <strong>{{ ResellerModule.reseller.firstname }} {{ ResellerModule.reseller.lastname }} </strong>
        </h5>
      </div>

      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'reseller-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>{{ $t('pages.reseller.form.apiData') }}</h4>
      </div>
      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>Api Token</strong></span>
          <span>{{ ResellerModule.reseller.api_token }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>Thetis Key</strong></span>
          <span>{{ ResellerModule.reseller.thetis_key }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('pages.reseller.form.apiRelease') }}</strong></span>
          <span class="ml-2">{{ ResellerModule.reseller.apiVersion }}</span>
        </div>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>{{ $t('common.globalInfo') }}</h4>
      </div>

      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.company') }}</strong></span>
          <template v-if="ResellerModule.reseller.company ">
            <span>{{ ResellerModule.reseller.company }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('pages.reseller.tableList.mailReseller') }}</strong></span>
          <template v-if="ResellerModule.reseller.username ">
            <span>{{ ResellerModule.reseller.username }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.firstname') }}</strong></span>
          <template v-if="ResellerModule.reseller.firstname ">
            <span>{{ ResellerModule.reseller.firstname }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.lastname') }}</strong></span>
          <template v-if="ResellerModule.reseller.lastname ">
            <span>{{ ResellerModule.reseller.lastname }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>{{ $t('pages.reseller.form.salesInfo') }}</h4>
      </div>

      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('pages.reseller.form.vat') }}</strong></span>
          <template v-if="ResellerModule.reseller.vat ">
            <span>{{ ResellerModule.reseller.vat }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('pages.reseller.form.paymentDeadline') }}</strong></span>
          <template v-if="ResellerModule.reseller.paymentDeadline ">
            <span>{{ ResellerModule.reseller.paymentDeadline|moment('DD/MM/Y') }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('pages.reseller.form.paymentMethod') }}</strong></span>
          <template v-if="ResellerModule.reseller.paymentMode ">
            <span v-if="ResellerModule.reseller.paymentMode === 'none'">{{ data.paymentMode[0].name }}</span>
            <span v-else-if="ResellerModule.reseller.paymentMode === 'bank-card'">{{ data.paymentMode[1].name }}</span>
            <span v-else-if="ResellerModule.reseller.paymentMode === 'cash'">{{ data.paymentMode[2].name }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>Type</strong></span>
          <template v-if="ResellerModule.reseller.type ">
            <span>{{ ResellerModule.reseller.type }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('pages.reseller.form.defaultResellerCountryCodeForQuote') }}</strong></span>
          <template v-if="ResellerModule.reseller.country.countryCode ">
            <span>{{ ResellerModule.reseller.country.countryCode }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>{{ $t('pages.reseller.form.billingInfo') }}</h4>
      </div>

      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.firstname') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingFirstname">
            <span>{{ ResellerModule.reseller.billingFirstname }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.lastname') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingLastname">
            <span>{{ ResellerModule.reseller.billingLastname }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.address') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingAddress">
            <span>{{ ResellerModule.reseller.billingAddress }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.secondAddress') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingAddress2">
            <span>{{ ResellerModule.reseller.billingAddress2 }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.city') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingCity">
            <span>{{ ResellerModule.reseller.billingCity }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.zipCode') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingZipCode">
            <span>{{ ResellerModule.reseller.billingZipCode }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.country') }}</strong></span>
          <template v-if="ResellerModule.reseller.billingCountry">
            <span>{{ ResellerModule.reseller.billingCountry }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>{{ $t('pages.reseller.form.deliveryInfo') }}</h4>
      </div>

      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.firstname') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryFirstname">
            <span>{{ ResellerModule.reseller.deliveryFirstname }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.lastname') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryLastname">
            <span>{{ ResellerModule.reseller.deliveryLastname }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.address') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryAddress">
            <span>{{ ResellerModule.reseller.deliveryAddress }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.secondAddress') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryAddress2">
            <span>{{ ResellerModule.reseller.deliveryAddress2 }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.city') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryCity">
            <span>{{ ResellerModule.reseller.deliveryCity }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.zipCode') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryZipCode">
            <span>{{ ResellerModule.reseller.deliveryZipCode }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>

        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.country') }}</strong></span>
          <template v-if="ResellerModule.reseller.deliveryCountry">
            <span>{{ ResellerModule.reseller.deliveryCountry }}</span>
          </template>
          <template v-else>
            <b-badge>{{ $t('common.none') }}</b-badge>
          </template>
        </div>
      </div>
    </div>
    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>Email</h4>
      </div>
      <div class="card-body" >
        <div class="d-flex mb-3" v-for="(mail, index) in ResellerModule.reseller.mailConfig" :key="index">
          <span class="w-50"><strong>{{ $t('common.email.' + index) }}</strong></span>
          <template v-if="mail">
            <span>{{ $t('common.yes') }}</span>
          </template>
          <template v-else>
            <span>{{ $t('common.no') }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      isActive: '',
      data: {
        paymentMode: [
          {name: 'Aucun', tag: 'none'},
          {name: this.$t('pages.reseller.form.bankCard'), tag: 'bank-card'},
          {name: this.$t('pages.reseller.form.species'), tag: 'cash'}
        ],
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchReseller', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    ...mapState(['ResellerModule'])
  },
  mounted() {
    this.isActive = (this.ResellerModule.reseller.isActive) ? "Oui" : "Non"
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
