<template>
  <div class="page-quote-show">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <div>
        <h5>
          {{ $tc('common.detailsOf', 0) }} {{ $tc('common.quote', 0) }}
          <strong class="text-secondary">(# {{ QuoteModule.quote.id }})</strong>
        </h5>
      </div>

      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'quote-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <div class="card-header">
        <h4>{{ $t('common.globalInfo') }}</h4>
      </div>
      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.status') }}</strong></span>
          <span>{{ QuoteModule.quote.status }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>ID</strong></span>
          <span>{{ QuoteModule.quote.id }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.company') }}</strong></span>
          <span>{{ QuoteModule.quote.user.company }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.reference') }}</strong></span>
          <span>{{ QuoteModule.quote.reference }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.price') }}</strong></span>
          <span>{{ QuoteModule.quote.price }} €</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.weight') }}</strong></span>
          <span>{{ QuoteModule.quote.weight }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.width') }}</strong></span>
          <span>{{ QuoteModule.quote.width }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.diameter') }}</strong></span>
          <span>{{ QuoteModule.quote.diameter }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.height') }}</strong></span>
          <span>{{ QuoteModule.quote.height }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.singleScenario') }}</strong></span>
          <span>{{ QuoteModule.quote.scenario.name }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.createdDate') }}</strong></span>
          <span>{{ QuoteModule.quote.createdAt|moment('DD/MM/Y') }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.internalReference') }}</strong></span>
          <span>{{ QuoteModule.quote.internalReference }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.shape') }}</strong></span>
          <span v-if="QuoteModule.quote.shape">{{ QuoteModule.quote.shape.name }}</span>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <h4>{{ $t('common.files') }}</h4>
      </div>
      <div class="card-body">
        <div class="" v-if="QuoteModule.quote.series">
          <div class="col-12" v-for="(serie, index) in QuoteModule.quote.series" :key="index">
            <div class="card p-2 mb-2">
              <div class="p-2">
                <div class="font-weight-bold">ID : </div>
                <div class="text-info font-weight-bold">{{ serie.id }}</div>
              </div>
              <div class="p-2">
                <div class="font-weight-bold">{{ $t('common.name') }} : </div>
                <div class="text-info font-weight-bold">{{ serie.name }}</div>
              </div>
              <div  class="p-2">
                <div class="font-weight-bold">{{ $t('common.quantity') }}: </div>
                <div>{{ serie.quantity }}</div>
              </div>
              <div  class="p-2">
                <div class="font-weight-bold">{{ $t('common.link') }} (s3): </div>
                <div>
                <a :href="serie.file" target="_blank">{{ serie.file }}</a>
                </div>
              </div >
              <div  class="p-2" v-if="QuoteModule.quote.status=='waiting_for_files'">
                <div>
                <input
                    class="btn btn-sm btn-primary"
                    type="file"
                    accept="application/pdf"
                    @change="handleFileUploadOnS3($event, serie)"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center"><button class="btn btn-success" v-on:click="validateQuote(QuoteModule.quote)">Valider le devis</button></div>
      </div>
    </div>

    {{/* Utilisateur */}}
    <div class="card border-0 shadow p-3" v-if="QuoteModule.quote.frontUser">
      <div class="card-header">
        <h4>{{ $tc('common.user', 0) }}</h4>
      </div>

      <div class="card-body">
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.lastname') }}</strong></span>
          <span>{{ (QuoteModule.quote.frontUser) ? QuoteModule.quote.frontUser.lastname : null }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.firstname') }}</strong></span>
          <span>{{ (QuoteModule.quote.frontUser) ? QuoteModule.quote.frontUser.firstname : null }}</span>
        </div>
        <div class="d-flex mb-3">
          <span class="w-25"><strong>{{ $t('common.mail') }}</strong></span>
          <span>{{ (QuoteModule.quote.frontUser) ? QuoteModule.quote.frontUser.username : null }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import QuoteSerieService from "@/services/QuoteSerieService";
import QuoteService from "@/services/QuoteService";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: true,
        fullPage: true,
      },
      isActive: '',
      data: {

      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    async handleFileUploadOnS3($event, serie)
    {
      //let fileModel = await this.handleFileToBase64($event.target.files)

      let formData = new FormData();
      formData.append('file', $event.target.files[0]);
      formData.append('id', serie.id);
      formData.append('name', serie.name);

      await this.$swal({
        title: this.$t('common.uploadFile'),
        text:  this.$t('common.validUpload'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.validate'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          this.showLoader()



          QuoteSerieService.addFile(serie.id, formData)
              .then((response) => {
                this.$bvToast.toast(this.$t('pages.orderNotConformity.toast.newFile'), {
                  title: this.$t('pages.orderNotConformity.toast.downloadNewFile'),
                  variant: 'success',
                  solid: true
                })

                location.reload()

                // Set new file
                this.response = response.data

                this.haveFilesAddedButNotSubmitted = true;
                //this.hideLoader()
              })
              .catch(() => {
                this.hideLoader()
              })
        }
      })
    },
    async validateQuote(quote) {
      QuoteService.validateQuote(quote.id)
          .then((response) => {

            this.response = response;
            if (response.status == '400') {
              this.$bvToast.toast(this.$t('error.validateQuote'), {
                title: this.$t('common.error'),
                variant: 'danger',
                solid: true
              })
            }
            else {
              this.$bvToast.toast(this.$t('success.validateQuote'), {
                title: this.$t('common.success'),
                variant: 'success',
                solid: true
              })
            }
          })
          .catch(() => {

          })
    },
    /*----------------- PRIVATE METHODS ------------------*/
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchQuote', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    ...mapState(['QuoteModule'])
  },
  mounted() {
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
