import AxiosService from '@/services/AxiosServices'
import axios from "axios";

export default {
  /**
   * Get list of Quotes
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @param filters => array of filter
   * @param resellerId => is request is for Reseller or Admin
   * @param startDate
   * @param endDate
   * @returns {*}
   */
  getQuotes(perPage, page, order, search, filters, resellerId, startDate, endDate) {
    let url = 'admin/quotes'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search
    // Filter
    if(filters) {
      filters.forEach(function(filter) {
        url += '&_filter[]=' + filter
      })
    }
    // Reseller
    if(resellerId !== null)
      url += '&_resellerId=' + resellerId

    if(startDate !== null)
      url += '&_start_date=' + startDate
    if(endDate !== null)
      url += '&_end_date=' + endDate

    return AxiosService.callApi().get(url)
  },
  /**
   * Get Quote by id
   *
   * @param id
   * @returns {*}
   */
  getQuote(id) {
    return AxiosService.callApi().get('/admin/quotes/' + id)
  },
  validateQuote(id) {
    return AxiosService.callApi().patch('/quotes/' + id + '/validate')
  },
  exportQuote({type, order, search, filters, resellerId, startDate, endDate, propertiesToExport}) {
    let url = process.env.VUE_APP_API_URL + '/admin/quotes/export?'

    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search
    if(startDate !== null)
      url += '&_start_date=' + startDate
    if(endDate !== null)
      url += '&_end_date=' + endDate
    // Filter
    if(filters) {
      filters.forEach(function(filter) {
        url += '&_filter[]=' + filter
      })
    }
    // Reseller
    if(resellerId !== null)
      url += '&_resellerId=' + resellerId

    return axios({
      url: url,
      method: 'POST',
      responseType: 'blob',
      data: {
        type: type,
        propertiesToExport: propertiesToExport
      },
      headers: {
        common: {
          Accept: (type === 'csv') ? 'application/csv' : 'application/xlsx'
        },
        patch: {
          'Content-Type': (type === 'csv') ? 'application/csv' : 'application/xlsx'
        },
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      }
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: (type === 'csv') ? 'application/csv' : 'application/xlsx' }))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          (type === 'csv') ? 'quote-csv.csv' : 'quote-xlsx.xlsx'
        )
        document.body.appendChild(fileLink)
        fileLink.click();

        return Promise.resolve(true)
      })
  }
}
