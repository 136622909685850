<template>
  <div id="page-orientation">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="arrows-collapse" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.orientation.title') }}
      </h3>
      <div>
        <router-link
            class="btn btn-info mt-2"
            :to="{ name: 'orientation-create' }"
        >
          {{ $t('pages.orientation.createOrientation') }}
        </router-link>
      </div>
    </div>

    <hr />

    <div class="d-xl-flex justify-content-between mb-4">
      <div class="d-xl-flex mb-4 w-100">
        <div class="search mt-2 mt-md-0">
          <div class="input-group">
            <input
                type="text"
                class="form-control border"
                v-model="filter.search"
                :placeholder="$t('common.search.keyword')"
                v-on:keyup.enter="sendResearchRequest"
            >
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append">
              <button class="btn btn-success p-0 pl-2 pr-2" v-on:click="sendResearchRequest">
                {{ $t('common.search.research') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="OrientationModule.orientations.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="OrientationModule.orientations"
            :entityName="'user'"
            :actionList="configTableComponent.actionList"
            :specificAction="configTableComponent.specificAction"
            :isAction="true"
            @ordered="clickOrdered"
            @specific-action="handleSpecificAction"
        />

        <div v-if="OrientationModule.orientations.length === 0">
          <div v-if="filter.search != null" class="text-center mt-5">
            {{ $t('common.search.noResult') }}
          </div>
          <div v-else class="text-center mt-5">
            {{ $t('pages.orientation.noResultList') }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="count-elem my-auto">
            <b>{{ (filter.search) ? OrientationModule.totalRequest :  OrientationModule.total}}</b>
            {{ $t('common.result') }}
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="OrientationModule.orientations.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="(OrientationModule.totalRequest > 0) ? Math.ceil(OrientationModule.totalRequest / filter.elemPerPage) : Math.ceil(OrientationModule.total / filter.elemPerPage)"
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 10,
        order: null,
        search: null,
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true },
          {'index' : 'number', 'indexTrad' : 'common.number', 'align' : 'center', 'filter': true },
        ],
        actionList: [
          {
            'indexTrad': 'common.actions.edit',
            'routeName' : 'orientation-edit',
            'icon' : 'pencil-square',
            'icon-variant': 'info',
            'params' : true ,
            'link-action': true
          },
          {
            'index': 'delete',
            'indexTrad' : 'common.actions.delete',
            'icon' : 'trash',
            'icon-variant': 'danger',
            'isSpecificAction' : true
          },
        ]
      }
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchOrientations', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page
    })

  },
  updated() {
    this.hideLoader()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchOrientations', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrientations', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrientations', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.order = order

      this.$store.dispatch('fetchOrientations', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SWITCH / SPECIFIC ACTION ------------------*/
    /**
     * handle specific action (emit from table component)
     *
     * @param obj
     */
    handleSpecificAction(obj) {

      // Delete
      if(obj.action === 'delete') {
        this.showLoader()

        this.$swal({
          title: this.$t('pages.orientation.wantDelete'),
          text: this.$t('common.actionIrreversible'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#fa5c7c',
          cancelButtonColor: '#929292',
          confirmButtonText: this.$t('common.actions.delete')
        }).then((result) => {

          if (result.value) {
            this.$store.dispatch('deleteOrientation', {id: obj.id})
                .then(() => {
                  this.$bvToast.toast(this.$t('pages.orientation.deleteSuccess'), {
                    variant: 'success',
                    solid: true
                  })

                  this.$store.dispatch('fetchOrientations', {
                    perPage: this.filter.elemPerPage,
                    page: 1
                  })

                  this.hideLoader()
                })
                .catch((error) => {
                  this.hideLoader()

                  this.$bvToast.toast(error.message, {
                    title: this.$t('pages.orientation.errorDeleteTitle'),
                    variant: 'warning',
                    solid: true
                  })
                })
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['OrientationModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>

<style scoped>

</style>
