<template>
  <div id="page-scenario">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="folder" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.scenario.title') }}
      </h3>
    </div>

    <hr />

    <div class="d-xl-flex justify-content-between mb-4">
      <div class="d-xl-flex justify-content-between mb-4 w-100">
        <div class="search mt-2 mt-md-0">
          <div class="input-group">
            <input
                type="text"
                class="form-control border"
                v-model="filter.search"
                :placeholder="$t('common.search.keyword')"
                v-on:keyup.enter="sendResearchRequest"
            >
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append">
              <button class="btn btn-success p-0 pl-2 pr-2" v-on:click="sendResearchRequest">
                {{ $t('common.search.research') }}
              </button>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-warning text-white mt-2 mt-lg-0 mt-xl-0" v-on:click="refreshScenario">
            <b-icon class="mr-2" icon="arrow-repeat" font-scale="1.5" variant="white"></b-icon>
            {{ $t('pages.scenario.syncButton') }}
          </button>
        </div>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="ScenarioModule.scenarios.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="ScenarioModule.scenarios"
            :entityName="'scenario'"
            :actionList="configTableComponent.actionList"
            :isAction="true"
            @ordered="clickOrdered"
            @switch="handleSwitch"
        />
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="filter-elem-per-page">
              <select class="form-control" v-model="filter.elemPerPage" v-on:change="changeLimitList">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div class="count-elem my-auto ml-3">
              <b>{{ (filter.search) ? ScenarioModule.totalRequest :  ScenarioModule.total}}</b>
              {{ $t('common.result') }}
            </div>
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="ScenarioModule.scenarios.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="(ScenarioModule.totalRequest > 0) ? Math.ceil(ScenarioModule.totalRequest / filter.elemPerPage) : Math.ceil(ScenarioModule.total / filter.elemPerPage)"
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ScenarioService from '@/services/ScenarioService'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 25,
        order: {
          index: 'id',
          order: 'DESC'
        },
        search: null,
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true, 'order': 'DESC'},
          {'index' : 'uid', 'name' : 'Uid', 'align' : 'left', 'filter': true },
          {'index' : 'name', 'indexTrad' : 'common.name', 'align' : 'left', 'filter': true },
          {'index' : 'displayName', 'indexTrad' : 'common.displayName', 'align' : 'left', 'filter': true },
          {'index' : 'status', 'indexTrad' : 'common.status', 'align' : 'center', 'filter': false, 'switch': true }
        ],
        actionList: [
          {
            'indexTrad': 'common.actions.see',
            'routeName' : 'scenario-show',
            'icon' : 'eye',
            'icon-variant': 'success',
            'params' : true,
            'link-action': true
          },
          {
            'indexTrad': 'common.actions.edit',
            'routeName' : 'scenario-edit',
            'icon' : 'pencil-square',
            'icon-variant': 'info',
            'params' : true,
            'link-action': true
          },
        ]
      }
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchScenarios', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page,
      order: this.filter.order
    })
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.hideLoader()
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SELECT LIMIT LIST ------------------*/
    changeLimitList() {
      this.showLoader()

      this.$store.dispatch('fetchScenarios', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: this.filter.order
      })
          .then(() => {
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    /*----------------- REFRESH SCENARIO ------------------*/
    refreshScenario()
    {
      this.showLoader()

      ScenarioService.refreshScenario()
        .then(() => {
          this.$store.dispatch('fetchScenarios', {
            perPage: this.filter.elemPerPage,
            page: this.filter.page,
            order: this.filter.order
          })

          this.hideLoader()
        })
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchScenarios', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchScenarios', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchScenarios', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.filter.order = order

      this.$store.dispatch('fetchScenarios', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SWITCH / SPECIFIC ACTION ------------------*/
    /**
     * Handle switch (emit from table component)
     */
    handleSwitch(obj) {
      // Get Scenario
      let scenario = this.$store.getters.getScenarioById(obj.id)

      // Security
      if(!scenario) {
        this.$bvToast.toast(this.$t('pages.scenario.notFound'), {
          title: this.$t('common.error').toUpperCase(),
          variant: 'danger',
          solid: true
        })
      }

      // Update status
      scenario.status = obj.value

      // Loader
      this.showLoader()

      // Call API
      this.$store.dispatch('updateScenario', { id: scenario.id, scenario: scenario })
          .then(() => {
            this.hideLoader()

            this.$bvToast.toast((scenario.status) ? this.$t('pages.scenario.scenarioActive') : this.$t('pages.scenario.scenarioDisabled') , {
              title: this.$t('pages.scenario.editSuccess'),
              variant: (scenario.status) ? 'success' : 'warning',
              solid: true
            })
          })
          .catch(() => {
            this.hideLoader()
          })
    },
  },
  computed: {
    ...mapState(['ScenarioModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>

<style scoped>

</style>
