import QuoteService from '@/services/QuoteService'

export default {
  state: {
    quote: {},
    quotes: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_QUOTE(state, quote) {
      state.quotes.push(quote)
    },
    SET_QUOTES(state, quotes) {
      state.quotes = quotes
    },
    SET_QUOTES_TOTAL(state, total) {
      state.total = total
    },
    SET_QUOTES_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_QUOTE(state, quote) {
      state.quote = quote
    },
  },
  actions: {
    /**
     * Fetch in state list of quotes
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     * @param filters
     * @param resellerId
     * @param startDate
     * @param endDate
     */
    fetchQuotes({ commit }, { perPage, page, order, search, filters, resellerId, startDate, endDate }) {
      return QuoteService.getQuotes(
          (perPage) ? perPage: null,
          (page) ? page: null,
          (order) ? order : null,
          (search) ? search : null,
        (filters && filters.length > 0) ? filters : null,
          resellerId,
          startDate,
          endDate
        )
        .then(response => {
          commit('SET_QUOTES', response.data.list)
          commit('SET_QUOTES_TOTAL', response.data.total)
          commit('SET_QUOTES_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on quote list: ', error)
        })
    },
    /**
     * Fetch in state a Quote
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchQuote({ commit }, id)
    {
      QuoteService.getQuote(id)
        .then(response => {
          commit('SET_QUOTE', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Quote : ', error)
        })
    },
  },
  getters: {
    /**
     * Get Quote by id in list quotes
     *
     * @param state
     * @returns {function(*): *}
     */
    getQuoteById: state => id => {
      return state.quotes.find(quote => quote.id === id)
    }
  }
}
