import MargePriceService from '@/services/MargePriceService'

export default {
  state: {
    margePrice: {},
    margePrices: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_MARGE_PRICE(state, margePrice) {
      state.margePrices.push(margePrice)
    },
    SET_MARGE_PRICES(state, margePrices) {
      state.margePrices = margePrices
    },
    SET_MARGE_PRICES_TOTAL(state, total) {
      state.total = total
    },
    SET_MARGE_PRICES_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_MARGE_PRICE(state, margePrice) {
      state.margePrice = margePrice
    },
  },
  actions: {
    /**
     * Fetch in state list of mandrels
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchMargePrices({ commit }) {
      return MargePriceService.getMargePrices(
        )
        .then(response => {
          commit('SET_MARGE_PRICES', response.data)
          //commit('SET_MARGE_PRICES_TOTAL', response.data)
          //commit('SET_MARGE_PRICES_TOTAL_REQUEST', response.data)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on prices list: ', error)
        })
    },
    
}}
