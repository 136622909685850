<template>
  <div id="page-category">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-4" icon="flag" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.country.title') }}
      </h3>
    </div>

    <hr />

    <div class="form-group">
      <label class="font-weight-bold mb-4">{{ $t('pages.country.checkbox-label') }} :</label>

      <div class="" v-for="(country, index) in CountryModule.countries" :key="index">
        <label :for="'country-' + index" class="ml-2">{{ country.name }} ({{country.countryCode}})</label>

        <input
            class="ml-3 form-check-input"
            type="checkbox"
            :id="'country-' + index"
            v-model="country.isEnabledForDelivery"
            @change="updateCountry($event, country)"
        />

      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },

    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchCountries', {
      lang: 'fr',
    }).finally(
        this.hideLoader
    )

  },
  // updated() {
  //   this.hideLoader()
  // },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    updateCountry(event, country) {

      console.log(event)
      console.log(country)
      this.showLoader()

      this.$store.dispatch('updateCountry', {
        id: country.id,
        country: country
      }).finally(
          this.hideLoader
      )
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchCategories', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null
      })
          .then(() => {
            this.hideLoader()
          })
    },
  },
  computed: {
    ...mapState(['CountryModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
  }
}
</script>

<style scoped>

</style>
