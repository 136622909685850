<template>
  <div id="page-shape-edit">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <div>
        <h5>
          {{ $t('pages.shape.editShape') }}:
          <strong>{{ ShapeModule.shape.name }}</strong>
        </h5>
      </div>
      <div>
        <router-link
            class="btn btn-outline-primary mb-3"
            :to="{ name: 'shape-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudShape
        :shape="ShapeModule.shape"
        :id="ShapeModule.shape.id"
        :actionForm="'edit'"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      }
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchShape', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    ...mapState(['ShapeModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    FormCrudShape: () => import('@/components/pages/shape/FormCrudShape.vue')
  }
}
</script>

<style scoped>

</style>
