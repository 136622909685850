<template>
  <div id="page-paper-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.paper.createPaper') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'paper-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudPaper
        :paper="paper"
        :list-finishes="[]"
        :actionForm="'create'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      paper: {}
    }
  },
  components : {
    FormCrudPaper: () => import('@/components/pages/paper/FormCrudPaper')
  }
}
</script>

<style scoped>

</style>
