import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of Order request cancel
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @param resellerId => is request is for Reseller or Admin
   * @returns {*}
   */
  getOrderRequestCancels(perPage, page, order, search, resellerId) {
    let url = 'admin/order_request_cancels'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    // Reseller
    if(resellerId !== null)
      url += '&_resellerId=' + resellerId

    return AxiosService.callApi().get(url)
  },
  editOrderRequestCancel(id, data) {
    return AxiosService.callApi().patch('/admin/order_request_cancels/' + id, data)
  },
  cancelRespond(id, data) {
    return AxiosService.callApi().patch('/admin/order_request_cancels/cancel/' + id, data)
  }
}
