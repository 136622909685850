import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of Dimensions
   *
   * @returns {*}
   */
  getDimensions() {
    return AxiosService.callApi().get('admin/dimensions')
  },
  /**
   * Get Dimension by id
   *
   * @param id
   * @returns {*}
   */
  getDimension(id) {
    return AxiosService.callApi().get('/admin/dimensions/' + id)
  },
  /**
   * Create Dimension
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/dimensions', data)
  },
  /**
   * Edit Dimension
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/dimensions/' + id, data)
  },
  /**
   * Delete Dimension
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/dimensions/' + id)
  }
}
