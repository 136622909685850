<template>
  <div id="page-reseller-profile">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.reseller.id.titleProfile') }}</h5>
    </div>

    {{ /* Form component */ }}
    <FormCrudReseller
        :reseller="ResellerModule.reseller"
        :id="ResellerModule.reseller.id"
        :selected-type="getSelectedType"
        :actionForm="'edit'"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      dataPage: {
        title: 'Page Profil'
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchReseller', this.$store.getters.getCurrentUser.id)
      .then(() => {
        this.hideLoader()
      })
    .catch(() => {
      this.hideLoader()
    })
  },
  computed: {
    /**
     * Set var selectedType
     */
    getSelectedType()
    {
      return (this.ResellerModule.reseller && this.ResellerModule.reseller.type) ? this.ResellerModule.reseller.type : null
    },
    ...mapState(['ResellerModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    FormCrudReseller: () => import('@/components/pages/reseller/FormCrudReseller')
  }
}
</script>
