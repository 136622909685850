import CountryService from "@/services/CountryService";

export default {
  state: {
    countries: [],
  },
  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries
    }
  },
  actions: {
    fetchCountries({ commit }) {
      return CountryService.listCountries()
        .then(response => {
            commit('SET_COUNTRIES', CountryService.sortCountriesList(response.data))

            return Promise.resolve(CountryService.sortCountriesList(response.data))
        })
        .catch(() => {
          return 'An error occurred while fetching countries'
        })
    },
    // eslint-disable-next-line no-unused-vars
    updateCountry({commit}, {id, country})
    {
      return CountryService.edit(id, country)
          .then((response) => {
            if(response.data.errors)
            {
              return Promise.reject(response.data.errors)
            }
          })
    },
  },
  getters: {
    countriesList(state) {
      return state.countries;
    },
  }
}
