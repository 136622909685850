import UserService from '@/services/UserService'

export default {
  state: {
    user: {},
    users: [],
    total: null,
    totalRequest: null,
    roles: [
      {'index': ["ROLE_ADMIN"], 'tag': 'ROLE_ADMIN', 'name' : 'Admin'},
      {'index': ["ROLE_READER"], 'tag': 'ROLE_READER', 'name' : 'Reader'}
    ],
  },
  mutations: {
    ADD_USER(state, user) {
      state.users.push(user)
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_USERS_TOTAL(state, total) {
      state.total = total
    },
    SET_USERS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    /**
     * Fetch in state list of users
     *
     * @param commit
     * @param data
     */
    fetchUsers({ commit }, data) {
      return UserService.getUsers(data)
        .then(response => {
          commit('SET_USERS', response.data.list)
          commit('SET_USERS_TOTAL', response.data.total)
          commit('SET_USERS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on user list: ', error)
        })
    },
    /**
     * Fetch in state a user
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchUser({ commit }, id)
    {
      UserService.getUser(id)
        .then(response => {
          commit('SET_USER', response.data)
        })
        .catch((error) => {
          console.log('There was an error on user: ', error)
        })
    },
    /**
     * Create user
     *
     * @param commit
     * @param user
     */
    createUser({ commit }, user)
    {
      return UserService.create(user)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_USER', user)
          return Promise.resolve()
        })
    },
    /**
     * Update user
     *
     * @param commit
     * @param id
     * @param user
     * @returns {*}
     */
    updateUser({ commit }, {id, user})
    {
      return UserService.edit(id, user)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_USER', response.data)
        })
    },
    /**
     * Update user
     *
     * @param commit
     * @param id
     * @param user
     * @returns {*}
     */
    updateIsActiveUser({ commit }, {id, user})
    {
      return UserService.editIsActive(id, user)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_USER', response.data)
        })
    },
    /**
     * Delete user
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteUser({ commit }, {id}) {
      return UserService.delete(id)
    }
  },
  getters: {
    /**
     * Get user by id in list users
     *
     * @param state
     * @returns {function(*): *}
     */
    getUserById: state => id => {
      return state.users.find(user => user.id === id)
    }
  }
}
