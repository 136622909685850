import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Refresh scenario
   */
  refreshScenario() {
    return AxiosService.callApi().get('admin/scenarios/refresh')
  },
  /**
   * Get list of Scenarios
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getScenarios(perPage, page, order, search) {
    let url = 'admin/scenarios'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  /**
   * Get Scenario by id
   *
   * @param id
   * @returns {*}
   */
  getScenario(id) {
    return AxiosService.callApi().get('/admin/scenarios/' + id)
  },
  /**
   * Edit scenario
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/scenarios/' + id, data)
  },
  /**
   * Delete scenario
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/scenarios/' + id)
  }
}
