import OrientationService from '@/services/OrientationService'

export default {
  state: {
    orientation: {},
    orientations: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_ORIENTATION(state, orientation) {
      state.orientations.push(orientation)
    },
    SET_ORIENTATIONS(state, orientations) {
      state.orientations = orientations
    },
    SET_ORIENTATIONS_TOTAL(state, total) {
      state.total = total
    },
    SET_ORIENTATIONS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_ORIENTATION(state, orientation) {
      state.orientation = orientation
    },
  },
  actions: {
    /**
     * Fetch in state list of orientations
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchOrientations({ commit }, { perPage, page, order, search }) {
      return OrientationService.getOrientations(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_ORIENTATIONS', response.data.list)
          commit('SET_ORIENTATIONS_TOTAL', response.data.total)
          commit('SET_ORIENTATIONS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on Orientation list: ', error)
        })
    },
    /**
     * Fetch in state a orientation
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchOrientation({ commit }, id)
    {
      OrientationService.getOrientation(id)
        .then(response => {
          commit('SET_ORIENTATION', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Orientation : ', error)
        })
    },
    /**
     * Create Orientation
     *
     * @param commit
     * @param orientation
     */
    createOrientation({ commit }, orientation)
    {
      return OrientationService.create(orientation)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_ORIENTATION', orientation)
          return Promise.resolve()
        })
    },
    /**
     * Update Orientation
     *
     * @param commit
     * @param id
     * @param orientation
     * @returns {*}
     */
    updateOrientation({ commit }, {id, orientation})
    {
      return OrientationService.edit(id, orientation)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_ORIENTATION', response.data)
        })
    },
    /**
     * Delete Orientation
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteOrientation({ commit }, {id}) {
      return OrientationService.delete(id)
    }
  },
  getters: {
    /**
     * Get Orientation by id in list orientations
     *
     * @param state
     * @returns {function(*): *}
     */
    getOrientationById: state => id => {
      return state.orientations.find(orientation => orientation.id === id)
    }
  }
}
