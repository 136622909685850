<template>
  <div id="page-quote">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between align-items-center">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="clipboard-check" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.quote.title') }}
      </h3>
      <div class="d-flex mb-2 mb-lg-2 mb-xl-0">
        <div>
          <button v-if="this.$store.getters.userIsAdmin" class="btn btn-sm btn-dark" @click.prevent="exportType = 'csv'; showExportModal = true">
            Export (CSV)
          </button>
        </div>
        <div>
          <button v-if="this.$store.getters.userIsAdmin" class="btn btn-sm btn-dark ml-3" @click.prevent="exportType = 'xlsx'; showExportModal = true">
            Export (XLSX)
          </button>
        </div>
      </div>
    </div>

    <div class="div-modal">
      <b-modal
          :key="100"
          id="quotes-export-modal"
          ref="modal"
          centered
          :title="$t('exportModal.title')"
          v-model="showExportModal"
          @hidden="showExportModal = false"
          header-bg-variant="primary"
          header-text-variant="light"
          body-bg-variant="light"
          footer-bg-variant="light"
      >
        <hr />
        <div class="d-flex mb-4">
          <button class="btn btn-success mr-2" v-on:click.prevent="checkAllProperties()">
            {{ $t('pages.dashboard.graph.formPeriod.select.button.check') }}
          </button>
          <button class="btn btn-danger" v-on:click.prevent="uncheckAllProperties()">
            {{ $t('pages.dashboard.graph.formPeriod.select.button.unCheck') }}
          </button>
        </div>
        <div class="" v-for="(exportProperty, index) in propertiesToExport" :key="index">
          <label :for="'exportProperty-' + index" class="ml-2">{{ $t(exportProperty.propertyLabel)}}</label>
          <input
              class="ml-3 form-check-input"
              type="checkbox"
              :id="'exportProperty-' + index"
              v-model="exportProperty.isSelected"
          />
        </div>

        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="secondary" @click="cancel()">
            {{ $t('common.cancel') }}
          </b-button>
          <b-button size="sm" variant="success" @click="exportQuotes()">
            Export ({{ exportType }})
          </b-button>
        </template>
      </b-modal>
    </div>

    <hr />

    <div class="d-xl-flex align-items-center mb-3 mt-3">
      <div class="d-xl-flex w-100  flex-wrap">
        <div class="search d-flex  mr-lg-3 mb-2 mt-lg-0 mt-xl-0">
          <div class="input-group">
            <input
                type="text"
                class="form-control border"
                v-model="filter.search"
                :placeholder="$t('common.search.keyword')"
                v-on:keyup.enter="sendResearchRequest"
            >
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append d-block">
              <button class="btn btn-success" v-on:click="sendResearchRequest">
                {{ $t('common.search.research') }}
              </button>
            </div>
          </div>
        </div>

        {{/* FILTER RESELLER */}}
        <AutoComplete
            class=" mr-lg-3 mb-2 mt-lg-0 mt-xl-0"
            entity="reseller"
            :suggestions="listResellerCompany"
            :research-label="$tc('common.reseller', 2)"
            :placeholder="$t('common.find') + $tc('common.reseller', 0)"
            :is-listing-page="true"
            @click-suggestion="clickSuggestionAutocomplete"
            ref="resellerAutocomplete"
        />
        <div class="d-inline-flex align-items-center">
          <div class="mr-3 mt-lg-0 mt-xl-0">
            <b-form-datepicker @input="resetPagination(); fetchQuotes()" :placeholder="$t('common.startDate')" id="start-date-datepicker" label-help="" label-no-date-selected="Aucune date sélectionnée" v-model="filter.startDate" class="mb-2"></b-form-datepicker>
          </div>
          <div class="mr-3 mt-lg-0 mt-xl-0 ">
            <b-form-datepicker @input="resetPagination(); fetchQuotes()" :placeholder="$t('common.endDate')" id="end-date-datepicker" label-help="" label-no-date-selected="Aucune date sélectionnée" v-model="filter.endDate" class="mb-2"></b-form-datepicker>
          </div>
        </div>
        <div class="d-block">
          <div class=" mr-lg-3 mb-2 mt-lg-0 mt-xl-0 ">
            <b-button
                class="active"
                variant="outline-primary"
                v-on:click="resetFilters"
            >
              {{ $t('common.reset') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    {{ /* Badge filter reseller */ }}
    <div class="d-flex mb-4" v-if="listBadgeFilterReseller.length > 0">
      <div v-for="(reseller, index) in listBadgeFilterReseller" :key="index">
        <b-badge class="p-2 ml-2 c-pointer" variant="dark" v-on:click="removeFilterReseller(reseller.result)">
          {{ reseller.result.searchable }}
          <b-icon
              class="ml-2"
              icon="x"
              font-scale="1.5"
              variant="light"
          >
          </b-icon>
        </b-badge>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="QuoteModule.quotes.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="QuoteModule.quotes"
            :entityName="'quote'"
            :actionList="configTableComponent.actionList"
            :isAction="true"
            @ordered="clickOrdered"
        />

        <div v-if="QuoteModule.quotes.length === 0">
          <div v-if="filter.search != null" class="text-center mt-5">
            {{ $t('common.search.noResult') }}
          </div>
          <div v-else class="text-center mt-5">
            {{ $t('pages.quote.noResultList') }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="filter-elem-per-page">
              <select class="form-control" v-model="filter.elemPerPage" v-on:change="changeLimitList">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div class="count-elem my-auto ml-3">
              <b>{{ (filter.search) ? QuoteModule.totalRequest :  QuoteModule.total}}</b>
              {{ $t('common.result') }}
            </div>
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="QuoteModule.quotes.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="(QuoteModule.totalRequest > 0) ? Math.ceil(QuoteModule.totalRequest / filter.elemPerPage) : Math.ceil(QuoteModule.total / filter.elemPerPage)"
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QuoteService from '@/services/QuoteService'
import {quotesExportProperties} from "@/services/GlobalService";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 25,
        order: {
          index: 'createdAt',
          order: 'DESC'
        },
        startDate: null,
        endDate: null,
        search: null,
      },
      showExportModal: false,
      exportType: null,
      propertiesToExport: quotesExportProperties,
      listFilterResellerCompany: [],
      listBadgeFilterReseller: [],
      listResellerCompany: [],
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true },
          {'index' : 'status', 'indexTrad' : 'common.status', 'align' : 'left', 'filter': false },
          {'index' : 'user', 'indexTrad' : 'common.company', 'align' : 'left', 'filter': true, 'relation': true },
          {'index' : 'price', 'indexTrad' : 'common.price', 'align' : 'center', 'filter': true, 'money': true },
          {'index' : 'resalePrice', 'indexTrad' : 'common.resalePrice', 'align' : 'center', 'filter': true, 'money': true },
          {'index' : 'reference', 'indexTrad' : 'common.reference', 'align' : 'center', 'filter': true},
          {'index' : 'width', 'indexTrad' : 'common.width', 'align' : 'center', 'filter': true},
          {'index' : 'height', 'indexTrad' : 'common.height', 'align' : 'center', 'filter': true},
          {'index' : 'diameter', 'indexTrad' : 'common.diameter', 'align' : 'center', 'filter': true},
          {'index' : 'scenario', 'indexTrad' : 'common.singleScenario', 'align' : 'left', 'filter': true, 'relation': true},
          {'index' : 'createdAt', 'indexTrad' : 'common.createdDate', 'align' : 'center', 'filter': true, 'date': true, 'datetime': true, 'order': 'DESC'},
        ],
        actionList: [
          {
            'indexTrad': 'common.actions.see',
            'routeName' : 'quote-show',
            'icon' : 'eye',
            'icon-variant': 'success',
            'params' : true,
            'link-action': true
          }
        ],
      }
    }
  },
  created() {
    this.showLoader()
    this.fetchQuotes()

    // Reseller
    this.$store.dispatch('fetchResellers', {
      perPage: null,
      page: null,
    })

    this.formatResellersList()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FORMAT LIST RESELLERS ------------------*/
    formatResellersList() {
      this.$store.getters.getResellers.forEach((reseller) => {
        this.listResellerCompany.push({
          id: reseller.id,
          searchable: reseller.company
        })
      })
    },
    checkAllProperties() {
      this.propertiesToExport.map((propertyToExport) => {
        propertyToExport.isSelected = true;
        return propertyToExport
      })
    },
    uncheckAllProperties() {
      this.propertiesToExport.map((propertyToExport) => {
        propertyToExport.isSelected = false;
        return propertyToExport
      })
    },
    exportQuotes() {

      this.showLoader()
      this.propertiesToExport = this.propertiesToExport.map((property) => {
        return {
          ...property,
          translatedLabel: this.$t(property.propertyLabel)
        }
      })

      QuoteService.exportQuote({
        type: this.exportType,
        order: this.filter.order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        propertiesToExport: this.propertiesToExport
      })
      .finally(() => {
        this.hideLoader()
      })
    },
    /*----------------- SELECT LIMIT LIST ------------------*/
    changeLimitList() {
      this.showLoader()

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SELECT FILTER ------------------*/
    clickSuggestionAutocomplete(model) {
      this.showLoader()
      this.resetPagination()
      let index = this.listFilterResellerCompany.findIndex(str => str === model.result.searchable)

      if(index > -1) {
        this.listFilterResellerCompany.splice(index, 1)
        this.listBadgeFilterReseller.splice(index, 1)
      } else {
        this.listFilterResellerCompany.push(model.result.searchable)
        this.listBadgeFilterReseller.push(model)
      }

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    removeFilterReseller(filterResellerObj) {
      this.showLoader()

      let index = this.listFilterResellerCompany.findIndex(str => str === filterResellerObj.searchable)

      if(index > -1) {
        this.listFilterResellerCompany.splice(index, 1)
        this.listBadgeFilterReseller.splice(index, 1)
      } else {
        this.listFilterResellerCompany.push(filterResellerObj.searchable)
        this.listBadgeFilterReseller.push(filterResellerObj)
      }

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()
      this.resetPagination()
      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.filter.order = order

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    fetchQuotes() {
      this.showLoader()

      this.$store.dispatch('fetchQuotes', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: this.filter.order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterResellerCompany.length > 0) ? this.listFilterResellerCompany : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
          .catch(() => {
            this.hideLoader()
          })
    },
    resetFilters() {
      this.filter.startDate = null;
      this.filter.endDate = null;
      this.filter.search = null;
      this.listFilterResellerCompany = []
      this.listBadgeFilterReseller = []
      this.resetPagination()
      this.$refs.resellerAutocomplete.$data.selection = ''
      this.fetchQuotes()
    },
    resetPagination() {
      this.filter.page = 1;
      this.filter.elemPerPage = 25;
    }
  },
  computed: {
    ...mapState(['QuoteModule']),
    ...mapState(['ResellerModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination'),
    AutoComplete: () => import('@/components/_shared/autoComplete')
  }
}
</script>

<style scoped>

</style>
