<template>
  <div id="app">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="h-100" v-if="this.$route.name && $store.getters.loggedIn" >
      <Header
          :show-sidebar="showSidebar"
          @click-event-burger="handleClickEventBurger"
          @emit-from-header-locale-selected="onChangeLanguage"
      />
      <div class="wrapper d-flex w-100" v-if="$store.getters.loggedIn" >
        <SideBar
            class="sidebar"
            :class="(showSidebar) ? 'collapsed' : ''"
            @resize-collapsed="handleCollapsedResize"
        />
        <div class="main">
          <div class="app-container position-relative pl-2 pl-md-4 pr-2 pr-md-5 pb-2 pb-md-5 d-flex flex-column">
            <router-view :key="$route.fullPath"/>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-100">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      showSidebar: false,
      isMobile: false,
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- CLICK EVENT BURGER ------------------*/
    handleClickEventBurger(model) {
      this.showSidebar = model.showSidebar
      this.isMobile = model.isMobile
    },
    handleCollapsedResize(model) {
      this.showSidebar = model.showSidebar
    },

    /*----------------- SWITCH LANGUAGE ------------------*/
    onChangeLanguage(model) {
      this.$store.dispatch('changeLanguage', model)
      this.showLoader()
      this.$i18n.locale = model.locale
      setTimeout( () => this.hideLoader(), 1000)
    }
  },
  computed: {
    locale() {
      return this.$store.getters.getLocale
    }
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    Header: () => import('@/components/layouts/header'),
    SideBar: () => import('@/components/layouts/sidebar')
  },
}
</script>
