<template>
  <div id="page-order-check-files">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="file-earmark-break" font-scale="1.5" variant="danger"></b-icon>
        {{ $t('pages.orderNotConformity.title') }}
      </h3>
    </div>

    <hr />

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="OrderRequestNotConformityModule.requestNotConformityList.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="OrderRequestNotConformityModule.requestNotConformityList"
            :entityName="'orderNotConformity'"
            :actionList="configTableComponent.actionList"
            :isAction="true"
            @specific-action="tableActionEmit"
            @ordered="clickOrdered"
        />

        <div v-if="OrderRequestNotConformityModule.requestNotConformityList.length === 0">
          <div class="text-center mt-5">
            {{ $t('pages.orderNotConformity.noResultList') }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="count-elem my-auto">
            <b>
              {{ (filter.search) ? OrderRequestNotConformityModule.totalRequest :  OrderRequestNotConformityModule.total}}
            </b>
            {{ $t('common.result') }}
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="OrderRequestNotConformityModule.requestNotConformityList.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="(OrderRequestNotConformityModule.totalRequest > 0)
                ? Math.ceil(OrderRequestNotConformityModule.totalRequest / filter.elemPerPage)
                : Math.ceil(OrderRequestNotConformityModule.total / filter.elemPerPage)"
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>

    {{/* Modal not conformity response */}}
    <div class="div-modal">
      <b-modal
          :key="100"
          id="modal-order-not-conformity-response"
          ref="modal"
          size="xl"
          centered
          :title="$t('pages.orderNotConformity.modalResponse.title')"
          v-model="modal.open"
          @hidden="resetModal"
          @ok="formModalNotConformity"
          @close="handleModalClose"
          header-bg-variant="info"
          header-text-variant="light"
          body-bg-variant="light"
          footer-bg-variant="light"
      >
        <form ref="form">
          <div v-if="response.type === 'send-new-file'">
            <div>
              <h4 class="mb-4">{{ $t('pages.orderNotConformity.modalResponse.listYourFile') }}</h4>
              <div>
                <div class="col-12" v-for="(serie, index) in response.orderNotConformity.order.quote.series" :key="index">
                  <div class="card d-flex flex-row justify-content-between p-2 mb-2">
                    <div>
                      <span class="font-weight-bold">{{ $t('common.name') }} : </span>
                      <span class="text-info font-weight-bold">{{ serie.name }}</span>
                    </div>
                    <div>
                      <span class="font-weight-bold">{{ $t('common.link') }} (s3): </span>
                      <a :href="serie.file" target="_blank">{{ serie.file }}</a>
                    </div>
                    <input
                        class="btn btn-sm btn-primary"
                        type="file"
                        accept="application/pdf"
                        @change="handleFileUploadOnS3($event, serie)"
                    >
                  </div>
                </div>
              </div>
            </div>

            <hr />
          </div>

          <div class="form-group">
            <label for="order-not-conformity-comment">{{ $t('common.yourComment') }} :</label>
            <textarea
                class="form-control"
                id="order-not-conformity-comment"
                v-model="form.commentReseller"
                :placeholder="$t('common.yourComment')"
            >
            </textarea>
          </div>
        </form>

        <template #modal-footer="{ ok }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="success" @click="ok()">
            {{ $t('pages.orderNotConformity.modalResponse.sendAnswer') }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderNotConformityService from '@/services/OrderNotConformityService'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      modal: {
        open: false,
        data: false
      },
      form: {
        commentReseller: null,
        picture: null
      },
      response: {
        orderNotConformity: null,
        type: null
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 25,
        order: {
          index: 'id',
          order: 'DESC'
        },
        search: null,
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true },
          {'index' : 'order', 'indexTrad' : 'pages.orderNotConformity.tableList.orderNumber', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-not-conformity-order' },
          {'index' : 'reseller', 'indexTrad' : 'common.company', 'align' : 'center', 'filter': true, 'relation': true },
          {'index' : 'order', 'indexTrad' : 'common.ref', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-not-conformity-quote-reference'},
          {'index' : 'order', 'indexTrad' : 'common.orderStatus', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-status' },
          {'index' : 'commentAdmin', 'indexTrad' : 'pages.orderNotConformity.tableList.commentAdmin', 'align' : 'center'},
          {'index' : 'commentReseller', 'indexTrad' : 'pages.orderNotConformity.tableList.commentReseller', 'align' : 'center'},
          {'index' : 'responseReseller', 'indexTrad' : 'pages.orderNotConformity.tableList.resellerResponse', 'align' : 'center'},
          {'index' : 'createdAt', 'indexTrad' : 'common.created', 'align' : 'center', 'filter': true, 'date' : true, 'datetime': true, 'order': 'DESC'},
        ],
        actionList: [
          {
            'indexTrad' : 'pages.orderNotConformity.tableList.actions.seeOrder',
            'routeName' : 'order-show',
            'icon' : 'eye',
            'icon-variant': 'success',
            'params' : true,
            'link-action': true,
            'is-specific-id': true,
            'id-index': 'order',
            'from': 'not-conformity-index'
          },
          {
            'index': 'send-new-file',
            'indexTrad': 'pages.orderNotConformity.tableList.actions.sendNewFile',
            'icon' : 'file-arrow-down',
            'icon-variant': 'warning',
            'isSpecificAction' : true,
            'isResellerOnly': true
          },
          {
            'index': 'print-anyway',
            'indexTrad': 'pages.orderNotConformity.tableList.actions.printAnyway',
            'icon' : 'file-check',
            'icon-variant': 'info',
            'isSpecificAction' : true,
            'isResellerOnly': true
          },
        ]
      },
      haveFilesAddedButNotSubmitted: false
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchOrderRequestNotConformityList', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page,
      order: this.filter.order,
      search: (this.filter.search) ? this.filter.search : null,
      resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
    })
      .then(() => {
        this.hideLoader()
      })
      .catch((error) => {
        console.log(error)

        this.hideLoader()
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.filter.order = order

      this.$store.dispatch('fetchOrderRequestNotConformityList', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
        .then(() => {
          this.hideLoader()
        })
    },
    /*----------------- MODAL ------------------*/
    async formModalNotConformity() {
      let model = {
        id: this.response.orderNotConformity.id,
        form: this.form,
        responseType: this.response.type
      }

      this.showLoader()
      await this.$store.dispatch('responseResellerOrderNotConformity', model)
        .then(() => {
          this.haveFilesAddedButNotSubmitted = false;
          this.$store.dispatch('fetchOrderRequestNotConformityList', {
            perPage: this.filter.elemPerPage,
            page: this.filter.page,
            order: (this.filter.order) ? this.filter.order : null,
            search: (this.filter.search) ? this.filter.search : null,
            resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
          })
            .then(() => {
              this.hideLoader()

              this.$bvToast.toast(this.$t('pages.orderNotConformity.toast.answerSuccess'), {
                title: this.$t('pages.orderNotConformity.toast.sendResponse'),
                variant: 'success',
                solid: true
              })
            })
            .catch(() => {
              this.hideLoader()
            })
        })
      .catch(() => {
        this.hideLoader()
      })

      // WARNING : Reload for counter on sidebar
      location.reload()
    },
    handleModalClose(bvModalEvent) {
      if (this.haveFilesAddedButNotSubmitted) {
        bvModalEvent.preventDefault()
        this.$swal({
          title: "Vous n'avez pas validé vos fichiers !",
          text: "Des fichiers ont été ajoutés mais votre formulaire n'a pas été validé, êtes-vous sûr de vouloir fermer le formulaire ?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0acf97',
          cancelButtonColor: '#929292',
          confirmButtonText: "Oui, fermer le formulaire",
          cancelButtonText: "Fermer cette fenêtre puis valider ma saisie"
        }).then((result) => {
          if (result.value) {
            this.validateModalClose()
          }
        })
      }
    },
    validateModalClose() {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-order-not-conformity-response')
      })
    },
    resetModal()
    {
      this.modal.open = false
      this.modal.data = null
      this.form.commentReseller = null
    },
    /*----------------- ACTIONS TABLE ------------------*/
    tableActionEmit(objEmitTable) {
      this.modal.open = true
      this.modal.data = objEmitTable
      this.response.type = objEmitTable.action
      this.response.orderNotConformity = this.$store.getters.getOrderNotConformityById(objEmitTable.id)
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrderRequestNotConformityList', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
        .then(() => {
          this.hideLoader()
        })
        .catch(() => {
          this.hideLoader()
        })
    },
    /*----------------- FILES METHOD ------------------*/
    async handleFileUploadOnS3($event, serie)
    {
      let fileModel = await this.handleFileToBase64($event.target.files)

      await this.$swal({
        title: this.$t('pages.orderNotConformity.toast.downloadAgain'),
        text: serie.name + ':' + this.$t('pages.orderNotConformity.toast.wantChangeFile'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.validate'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          this.showLoader()

          let model = {
            id: this.response.orderNotConformity.id,
            serie: serie,
            fileModel: fileModel
          }

          OrderNotConformityService.uploadFileOrderNotConformity(model)
            .then((response) => {
              this.$bvToast.toast(this.$t('pages.orderNotConformity.toast.newFile'), {
                title: this.$t('pages.orderNotConformity.toast.downloadNewFile'),
                variant: 'success',
                solid: true
              })

              // Set new file
              this.response.orderNotConformity.order.quote.series = response.data

              this.haveFilesAddedButNotSubmitted = true;
              this.hideLoader()
            })
            .catch(() => {
              this.hideLoader()
            })
        }
      })
    },
    /*----------------- PRIVATE METHODS ------------------*/
    handleFileToBase64(eventFile)
    {
      let fileModel = {
        filename: eventFile[0].name,
        size: eventFile[0].size
      }

      // File Reader
      let fileReader = new FileReader()
      fileReader.onload = function() {
        return fileReader.result
      }
      fileReader.readAsDataURL(eventFile[0])

      fileReader.onloadend = function() {
        fileModel.base64 = fileReader.result
      }

      return fileModel
    }
  },
  computed: {
    ...mapState(['OrderRequestNotConformityModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>
