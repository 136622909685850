<template>
  <div id="page-reseller-profile">
    {{ /* LOADER */ }}
    <loading :active.sync="loader.isLoading" :can-cancel="false" :is-full-page="loader.fullPage"
      :opacity="loader.opacity" :color="loader.color"></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h3 class="">{{ $t('pages.margePrice.title') }}</h3>
    </div>

    <div class="text-center border mb-4">
      <h4>{{ $t('pages.margePrice.import') }}</h4>
      <div>
        <a href="/model/marge_price.csv" alt="Logo Adesa">{{ $t('pages.margePrice.downloadModel') }}</a>
      </div>
      <div>
        <input class="btn btn-sm" type="file" accept="application/csv" @change="handleFileUploadMargePrice($event)">
      </div>
    </div>

    <div>
      <ul v-for="(margePrice, index) in MargePriceModule.margePrices" :key="index">
        <li>{{ margePrice.scenario.uid }} - {{ margePrice.quantity }} - {{ margePrice.marge }} % -
          {{ margePrice.createdAt }}</li>
      </ul>
    </div>

    {{ /* Form component */ }}
    <!-- <FormCrudReseller
        :reseller="ResellerModule.reseller"
        :id="ResellerModule.reseller.id"
        :selected-type="getSelectedType"
        :actionForm="'edit'"
    /> -->
  </div>
</template>

<script>

import { mapState } from "vuex";
import MargePriceService from "@/services/MargePriceService";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      dataPage: {
        title: 'Marge prices'
      },
    }
  },
  methods: {
    async handleFileUploadMargePrice($event) {
      let formData = new FormData();
      formData.append('file', $event.target.files[0]);

      await this.$swal({
        title: this.$t('common.uploadFile'),
        text: this.$t('common.validUpload'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.validate'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          this.showLoader()
          MargePriceService.importMargePrices(formData)
            .then(() => {
              location.reload()
            })
            .catch(() => {
              this.hideLoader()
            })
        }
      })
    },
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchMargePrices', this.$store.getters.getCurrentUser.id)
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.hideLoader()
      })
  },
  computed: {
    /**
     * Set var selectedType
     */
    getSelectedType() {
      return (this.ResellerModule.reseller && this.ResellerModule.reseller.type) ? this.ResellerModule.reseller.type : null
    },
    ...mapState(['MargePriceModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
  }
}
</script>
