import ScenarioService from '@/services/ScenarioService'

export default {
  state: {
    scenario: {},
    scenarios: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_SCENARIO(state, scenario) {
      state.scenario.push(scenario)
    },
    SET_SCENARIOS(state, scenarios) {
      state.scenarios = scenarios
    },
    SET_SCENARIOS_TOTAL(state, total) {
      state.total = total
    },
    SET_SCENARIOS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_SCENARIO(state, scenario) {
      state.scenario = scenario
    },
  },
  actions: {
    /**
     * Fetch in state list of Scenarios
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchScenarios({ commit }, { perPage, page, order, search }) {
      return ScenarioService.getScenarios(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_SCENARIOS', response.data.list)
          commit('SET_SCENARIOS_TOTAL', response.data.total)
          commit('SET_SCENARIOS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on Scenarios list: ', error)
        })
    },
    /**
     * Fetch in state a Scenario
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchScenario({ commit }, id)
    {
      ScenarioService.getScenario(id)
        .then(response => {
          commit('SET_SCENARIO', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Scenario : ', error)
        })
    },
    /**
     * Create Scenario
     *
     * @param commit
     * @param scenario
     */
    createScenario({ commit }, scenario)
    {
      return ScenarioService.create(scenario)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_SCENARIO', scenario)
          return Promise.resolve()
        })
    },
    /**
     * Update Scenario
     *
     * @param commit
     * @param id
     * @param scenario
     * @returns {*}
     */
    updateScenario({ commit }, {id, scenario})
    {
      return ScenarioService.edit(id, scenario)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_SCENARIO', response.data)
        })
    },
    /**
     * Delete Scenario
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteScenario({ commit }, {id}) {
      return ScenarioService.delete(id)
    }
  },
  getters: {
    /**
     * Get Scenario by id in list Scenarios
     *
     * @param state
     * @returns {function(*): *}
     */
    getScenarioById: state => id => {
      return state.scenarios.find(scenario => scenario.id === id)
    },
    getScenarios: state => {
      return state.scenarios
    }
  }
}
