import ConfigEnvService from '@/services/ConfigEnvService'

export default {
  state: {
    configEnv: {},
    configsEnv: [],
  },
  mutations: {
    ADD_CONFIGS_ENV(state, configEnv) {
      state.configsEnv.push(configEnv)
    },
    SET_CONFIGS_ENV(state, configsEnv) {
      state.configsEnv = configsEnv
    },
    SET_CONFIG_ENV(state, configEnv) {
      state.configEnv = configEnv
    },
  },
  actions: {
    /**
     * Fetch in state list of Config env
     *
     * @param commit
     */
    fetchConfigsEnv({ commit }) {
      return ConfigEnvService.getConfigsEnv()
        .then(response => {
          commit('SET_CONFIGS_ENV', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on config env list: ', error)
        })
    },
    /**
     * Fetch in state a config env
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchConfigEnv({ commit }, id)
    {
      ConfigEnvService.getConfigEnv(id)
        .then(response => {
          commit('SET_CONFIG_ENV', response.data)
        })
        .catch((error) => {
          console.log('There was an error on config Env : ', error)
        })
    },
    /**
     * Create Config env
     *
     * @param commit
     * @param configEnv
     */
    createConfigEnv({ commit }, configEnv)
    {
      return ConfigEnvService.create(configEnv)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_CONFIGS_ENV', configEnv)
          return Promise.resolve()
        })
    },
    /**
     * Update Config env
     *
     * @param commit
     * @param id
     * @param configEnv
     * @returns {*}
     */
    updateConfigEnv({ commit }, {id, configEnv})
    {
      return ConfigEnvService.edit(id, configEnv)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_CONFIG_ENV', response.data)
        })
    },
    /**
     * Delete Config env
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteConfigEnv({ commit }, {id}) {
      return ConfigEnvService.delete(id)
    }
  },
  getters: {
    /**
     * Get Config env by id in list config env
     *
     * @param state
     * @returns {function(*): *}
     */
    getConfigEnvById: state => id => {
      return state.configsEnv.find(configEnv => configEnv.id === id)
    }
  }
}
