export default {
  state: {
    locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : navigator.language,
    ListLocales: [
      {
        'locale': 'fr',
        'label': 'Français',
        'flagImgName': 'fr.png'
      },
      {
        'locale': 'en',
        'label': 'English',
        'flagImgName': 'en.png'
      },
      {
        'locale': 'es',
        'label': 'Español',
        'flagImgName': 'es.png'
      }
    ]
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale;
    }
  },
  actions: {
    changeLanguage({commit}, params) {
      // Local
      localStorage.setItem('locale', params.locale)
      // Commit
      commit('SET_LOCALE', params.locale)
    },
  },
  getters: {
    getLocale(state) {
      return state.locale;
    },
    getLocalesListGetter(state) {
      return state.ListLocales
    }
  }
}
