import AxiosService from '@/services/AxiosServices'
import axios from 'axios'

export default {
  /**
   * Refresh files order
   */
  refreshFilesOrder(id) {
    return axios({
      url: process.env.VUE_APP_API_URL + '/admin/orders/refresh/' + id,
      method: 'GET',
      responseType: 'blob',
      headers: {
        common: {
          Accept: 'application/zip'
        },
        patch: {
          'Content-Type': 'application/zip'
        },
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      }
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: 'application/zip' }))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          'order-zip-' + id
        )
        document.body.appendChild(fileLink)
        fileLink.click();

        return Promise.resolve(true)
      })
  },
  /**
   * Cancel order
   *
   * @param id
   * @param data
   */
  cancelOrder(id, data) {
    return AxiosService.callApi().post('/order_request_cancels', data)
  },
  reSyncOrderFiles(id) {
    return AxiosService.callApi().get('/admin/orders/resync/files/' + id)
  },
  /**
   * Get list of Orders
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @param filters => array of filter
   * @param resellerId => is request is for Reseller or Admin
   * @param startDate
   * @param endDate
   * @returns {*}
   */
  getOrders(perPage, page, order, search, filters, resellerId, startDate, endDate) {
    let url = 'admin/orders'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search
    if(startDate !== null)
      url += '&_start_date=' + startDate
    if(endDate !== null)
      url += '&_end_date=' + endDate
    // Filter
    if(filters) {
      filters.forEach(function(filter) {
        url += '&_filter[]=' + filter
      })
    }

    // Reseller
    if(resellerId !== null)
      url += '&_resellerId=' + resellerId

    return AxiosService.callApi().get(url)
  },
  /**
   * Get list of order status
   * @returns {*}
   */
  getOrderStatus() {
    return AxiosService.callApi().get('/admin/status/orders')
  },
  /**
   * Get Order by id
   *
   * @param id
   * @returns {*}
   */
  getOrder(id) {
    return AxiosService.callApi().get('/admin/orders/' + id)
  },
  editOrderStatus(id, data) {
    return AxiosService.callApi().patch('/admin/status/orders/' + id, data)
  },
  editOrderListStatus(data) {
    return AxiosService.callApi().patch('/admin/status/list/orders', data)
  },
  exportOrder({type, order, search, filters, resellerId, startDate, endDate, propertiesToExport}) {

    let url = process.env.VUE_APP_API_URL + '/admin/orders/export?'
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search
    if(startDate !== null)
      url += '&_start_date=' + startDate
    if(endDate !== null)
      url += '&_end_date=' + endDate
    // Filter
    if(filters) {
      filters.forEach(function(filter) {
        url += '&_filter[]=' + filter
      })
    }

    // Reseller
    if(resellerId !== null)
      url += '&_resellerId=' + resellerId


    return axios({
      url: url,
      method: 'POST',
      responseType: 'blob',
      data: {
        type: type,
        propertiesToExport: propertiesToExport
      },
      headers: {
        common: {
          Accept: (type === 'csv') ? 'application/csv' : 'application/xlsx'
        },
        patch: {
          'Content-Type': (type === 'csv') ? 'application/csv' : 'application/xlsx'
        },
        Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
      }
    })
      .then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data],{ type: (type === 'csv') ? 'application/csv' : 'application/xlsx' }))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          (type === 'csv') ? 'order-csv.csv' : 'order-xlsx.xlsx'
        )
        document.body.appendChild(fileLink)
        fileLink.click();

        return Promise.resolve(true)
      })
  },
  addDeliveryNote(id, model) {
    return AxiosService.callApi().post('/orders/' + id + '/delivery_note', model, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
}
