<template>
  <div id="page-finishing-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.finishing.createFinishing') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'finishing-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudFinishing
        :finishing="finishing"
        :actionForm="'create'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      finishing: {}
    }
  },
  components : {
    FormCrudFinishing: () => import('@/components/pages/finishing/FormCrudFinishing')
  }
}
</script>

<style scoped>

</style>
