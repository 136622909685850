<template>
  <div id="page-category-scenario-create">
    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <h5 class="">{{ $t('pages.categoryScenario.createCategoryScenario') }}</h5>
      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'cat-sc-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudCategoryScenario
        :category-scenario="categoryScenario"
        :actionForm="'create'"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryScenario: {}
    }
  },
  components : {
    FormCrudCategoryScenario: () => import('@/components/pages/categoryScenario/FormCrudCategoryScenario')
  }
}
</script>
