<template>
  <div class="page-user-show">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between mb-md-4">
      <div>
        <h5>
          {{ $tc('common.detailsOf', 2) }} {{ $t('common.singleUser') }}:
          <strong>{{ UserModule.user.firstname }} {{ UserModule.user.lastname }} </strong>
        </h5>
      </div>

      <div>
        <router-link
            class="btn btn-outline-primary mt-2 mt-md-0"
            :to="{ name: 'user-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    <div class="card border-0 shadow p-3">
      <h3>{{ $t('common.globalInfo') }}</h3>
      <hr />

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.user.username') }}</strong></span>
        <span class="ml-2">{{ UserModule.user.username }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('common.firstname') }}</strong></span>
        <span class="ml-2">{{ UserModule.user.firstname }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('common.lastname') }}</strong></span>
        <span class="ml-2">{{ UserModule.user.lastname }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('common.company') }}</strong></span>
        <span class="ml-2">{{ UserModule.user.company }}</span>
      </div>

      <div class="d-flex mb-3">
        <span class="w-25"><strong>Rôle</strong></span>
        <div class="d-flex" v-for="(role, index) in UserModule.user.roles" :key="index">
          <b-badge class="p-1 ml-2" :variant="(role === 'ROLE_ADMIN') ? 'warning' : 'primary'">{{ role }}</b-badge>
        </div>
      </div>

      <h3 class="mt-4 mb-4">Config(s)</h3>
      <hr />

      <div class="d-flex mb-3">
        <span class="w-25"><strong>{{ $t('pages.user.activeUser') }}</strong></span>
        <b-badge class="ml-2" v-if="isActive" variant="success">{{ $t('common.yes') }}</b-badge>
        <b-badge class="ml-2" v-else variant="danger">{{ $t('common.no') }}</b-badge>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      isActive: '',
      dataPage: {
        title: 'Détails de l\'utilisateur : '
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchUser', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    ...mapState(['UserModule'])
  },
  mounted() {
    this.isActive = (this.UserModule.user.isActive) ? "Oui" : "Non"
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
