import store from '../store/index'

// APP
import NotFound from '@/views/app/404'
import ForgotPassword from '@/views/app/forgotPassword'
import ChangePassword from '@/views/app/changePassword'
// ACCOUNT
import Login from '@/views/login/index'
// Dashboard
import Dashboard from '@/views/dashboard/index'
// User
import UserIndex from '@/views/user/index'
import UserShow from '@/views/user/_id'
import UserEdit from '@/views/user/edit'
import UserCreate from '@/views/user/create'
// Reseller
import ResellerIndex from '@/views/reseller/index'
import ResellerShow from '@/views/reseller/_id'
import ResellerEdit from '@/views/reseller/edit'
import ResellerCreate from '@/views/reseller/create'
import ResellerProfile from '@/views/reseller/profile'
import ResellerMargePrice from '@/views/reseller/margePrice'
// Category
import CategoryIndex from '@/views/category/index'
import CategoryEdit from '@/views/category/edit'
import CategoryCreate from '@/views/category/create'
// CategoryScenario
import CategoryScenarioIndex from '@/views/categoryScenario/index'
import CategoryScenarioEdit from '@/views/categoryScenario/edit'
import CategoryScenarioCreate from '@/views/categoryScenario/create'
// Mandrel
import MandrelIndex from '@/views/mandrel/index'
import MandrelEdit from '@/views/mandrel/edit'
import MandrelCreate from '@/views/mandrel/create'
// Orientation
import OrientationIndex from '@/views/orientation/index'
import OrientationEdit from '@/views/orientation/edit'
import OrientationCreate from '@/views/orientation/create'
// Paper
import PaperIndex from '@/views/paper/index'
import PaperEdit from '@/views/paper/edit'
import PaperCreate from '@/views/paper/create'
// Dimension
import DimensionIndex from '@/views/dimension/index'
import DimensionEdit from '@/views/dimension/edit'
import DimensionCreate from '@/views/dimension/create'
// Finishing
import FinishingIndex from '@/views/finishing/index'
import FinishingEdit from '@/views/finishing/edit'
import FinishingCreate from '@/views/finishing/create'
// ProvideNumberPerRolls
import ProvideNumberPerRollsIndex from '@/views/provideNumberPerRolls/index'
import ProvideNumberPerRollsEdit from '@/views/provideNumberPerRolls/edit'
import ProvideNumberPerRollsCreate from '@/views/provideNumberPerRolls/create'
// Scenario
import ScenarioIndex from '@/views/scenario/index'
import ScenarioShow from '@/views/scenario/_id'
import ScenarioEdit from '@/views/scenario/edit'

import CountriesIndex from '@/views/country/index'
// Shape
import ShapeIndex from '@/views/shape/index'
import ShapeEdit from '@/views/shape/edit'
import ShapeCreate from '@/views/shape/create'

// Order
import OrderIndex from '@/views/order/index'
import OrderShow from '@/views/order/_id'
// Order request cancel
import OrderRequestCancelIndex from '@/views/orderRequestCancel/index'
// Order not conformity
import OrderNotConformityIndex from '@/views/orderNotConformity/index'
// Quote
import QuoteIndex from '@/views/quote/index'
import QuoteShow from '@/views/quote/_id'
// CMS CALCULATOR
import CmsCalculator from '@/views/cmsCalculator/index'
// CONFIG ENV
import ConfigEnvIndex from '@/views/configEnv/index'
// EXPORT TRAD
import TranslateExport from '@/views/translate/export'

const AppRoutes = [
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      authRequired: false,
      routePublic: true
    }
  },
  {
    path: '/change-password/:token/:id',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      routePublic: true,
    }
  },
]

const AccountRoutes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      authRequired: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve() {
        store.dispatch('logout', true)
      }
    }
  }
]

const DashboardRoute = [
  {
    path: '/',
    name: 'dashboard-index',
    component: Dashboard,
    meta: {
      authRequired: true
    }
  }
]

const UserRoutes = [
  {
    path: '/user',
    name: 'user-index',
    component: UserIndex,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user/show/:id',
    name: 'user-show',
    component: UserShow,
    props: true,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: UserCreate,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: UserEdit,
    props: true,
    meta: {
      authRequired: true
    }
  },
]

const ResellerRoutes = [
  {
    path: '/reseller',
    name: 'reseller-index',
    component: ResellerIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/reseller/show/:id',
    name: 'reseller-show',
    component: ResellerShow,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/reseller/create',
    name: 'reseller-create',
    component: ResellerCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/reseller/edit/:id',
    name: 'reseller-edit',
    component: ResellerEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/reseller/profile',
    name: 'reseller-profile',
    component: ResellerProfile,
    meta: {
      authRequired: true,
      routeAdmin: false
    }
  },
  {
    path: '/reseller/marge-price',
    name: 'reseller-marge-price',
    component: ResellerMargePrice,
    meta: {
      authRequired: true,
      routeAdmin: false
    }
  },
]

const CategoryRoutes = [
  {
    path: '/category',
    name: 'category-index',
    component: CategoryIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/category/create',
    name: 'category-create',
    component: CategoryCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/category/edit/:id',
    name: 'category-edit',
    component: CategoryEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const CategoryScenarioRoutes = [
  {
    path: '/category-scenario',
    name: 'cat-sc-index',
    component: CategoryScenarioIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/category-scenario/create',
    name: 'cat-sc-create',
    component: CategoryScenarioCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/category-scenario/edit/:id',
    name: 'cat-sc-edit',
    component: CategoryScenarioEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const MandrelRoutes = [
  {
    path: '/mandrel',
    name: 'mandrel-index',
    component: MandrelIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/mandrel/create',
    name: 'mandrel-create',
    component: MandrelCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/mandrel/edit/:id',
    name: 'mandrel-edit',
    component: MandrelEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const OrientationRoutes = [
  {
    path: '/orientation',
    name: 'orientation-index',
    component: OrientationIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/orientation/create',
    name: 'orientation-create',
    component: OrientationCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/orientation/edit/:id',
    name: 'orientation-edit',
    component: OrientationEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const PaperRoutes = [
  {
    path: '/paper',
    name: 'paper-index',
    component: PaperIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/paper/create',
    name: 'paper-create',
    component: PaperCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/paper/edit/:id',
    name: 'paper-edit',
    component: PaperEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const DimensionRoutes = [
  {
    path: '/dimension',
    name: 'dimension-index',
    component: DimensionIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/dimension/create',
    name: 'dimension-create',
    component: DimensionCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/dimension/edit/:id',
    name: 'dimension-edit',
    component: DimensionEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const FinishingRoutes = [
  {
    path: '/finishing',
    name: 'finishing-index',
    component: FinishingIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/finishing/create',
    name: 'finishing-create',
    component: FinishingCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/finishing/edit/:id',
    name: 'finishing-edit',
    component: FinishingEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const ProvideNumberPerRollsRoutes = [
  {
    path: '/provide-number-per-rolls',
    name: 'provide-number-per-rolls-index',
    component: ProvideNumberPerRollsIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/provide-number-per-rolls/create',
    name: 'provide-number-per-rolls-create',
    component: ProvideNumberPerRollsCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/provide-number-per-rolls/edit/:id',
    name: 'provide-number-per-rolls-edit',
    component: ProvideNumberPerRollsEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const ScenarioRoutes = [
  {
    path: '/scenario',
    name: 'scenario-index',
    component: ScenarioIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/scenario/show/:id',
    name: 'scenario-show',
    component: ScenarioShow,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/scenario/edit/:id',
    name: 'scenario-edit',
    component: ScenarioEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
]

const CountryRoutes = [
  {
    path: '/countries',
    name: 'countries-index',
    component: CountriesIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const OrderRoutes = [
  {
    path: '/order',
    name: 'order-index',
    component: OrderIndex,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/order/show/:id',
    name: 'order-show',
    component: OrderShow,
    props: true,
    meta: {
      authRequired: true
    }
  },
]

const OrderRequestCancelRoutes = [
  {
    path: '/order-request-cancel',
    name: 'request-cancel-index',
    component: OrderRequestCancelIndex,
    meta: {
      authRequired: true
    }
  }
]

const OrderNotConformityIndexRoutes = [
  {
    path: '/order-not-conformity',
    name: 'not-conformity-index',
    component: OrderNotConformityIndex,
    meta: {
      authRequired: true
    }
  }
]

const QuoteRoutes = [
  {
    path: '/quote',
    name: 'quote-index',
    component: QuoteIndex,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/quote/show/:id',
    name: 'quote-show',
    component: QuoteShow,
    props: true,
    meta: {
      authRequired: true
    }
  },
]

const CmsCalculatorRoutes = [
  {
    path: '/cms/calculator',
    name: 'cms-calculator-index',
    component: CmsCalculator,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const ConfigEnvRoutes = [
  {
    path: '/config',
    name: 'config-env-index',
    component: ConfigEnvIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const TranslateExportRoute = [
  {
    path: '/translate/export',
    name: 'translate-export-index',
    component: TranslateExport,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const ShapeRoutes = [
  {
    path: '/shape',
    name: 'shape-index',
    component: ShapeIndex,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/shape/create',
    name: 'shape-create',
    component: ShapeCreate,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  },
  {
    path: '/shape/edit/:id',
    name: 'shape-edit',
    component: ShapeEdit,
    props: true,
    meta: {
      authRequired: true,
      routeAdmin: true
    }
  }
]

const allRoutes = [
  ...AppRoutes,
  ...AccountRoutes,
  ...DashboardRoute,
  ...UserRoutes,
  ...ResellerRoutes,
  ...CategoryRoutes,
  ...CategoryScenarioRoutes,
  ...MandrelRoutes,
  ...OrientationRoutes,
  ...PaperRoutes,
  ...DimensionRoutes,
  ...FinishingRoutes,
  ...ProvideNumberPerRollsRoutes,
  ...ScenarioRoutes,
  ...OrderRoutes,
  ...OrderRequestCancelRoutes,
  ...OrderNotConformityIndexRoutes,
  ...QuoteRoutes,
  ...CmsCalculatorRoutes,
  ...ConfigEnvRoutes,
  ...TranslateExportRoute,
  ...CountryRoutes,
  ...ShapeRoutes
]

export { allRoutes }
