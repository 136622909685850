import OrderService from '@/services/OrderService'

export default {
  state: {
    order: {},
    orders: [],
    orderStatus: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_ORDER(state, order) {
      state.orders.push(order)
    },
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    SET_ORDER_STATUS(state, orderStatus) {
      state.orderStatus = orderStatus
    },
    SET_ORDERS_TOTAL(state, total) {
      state.total = total
    },
    SET_ORDERS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_ORDER(state, order) {
      state.order = order
    },
  },
  actions: {
    /**
     * Fetch in state list of orders
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     * @param filters
     * @param resellerId
     * @param startDate
     * @param endDate
     */
    fetchOrders({ commit }, { perPage, page, order, search, filters, resellerId, startDate, endDate }) {
      return OrderService.getOrders(
          (perPage) ? perPage: null,
          (page) ? page: null,
          (order) ? order : null,
          (search) ? search : null,
          (filters && filters.length > 0) ? filters : null,
          resellerId,
          startDate,
          endDate
        )
        .then(response => {
          commit('SET_ORDERS', response.data.list)
          commit('SET_ORDERS_TOTAL', response.data.total)
          commit('SET_ORDERS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on order list: ', error)
        })
    },
    /**
     * Fetch in state list of orders
     *
     * @param commit
     */
    fetchOrderStatus({ commit }) {
      return OrderService.getOrderStatus()
        .then(response => {
          commit('SET_ORDER_STATUS', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on order status list: ', error)
        })
    },
    /**
     * Fetch in state a Order
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchOrder({ commit }, id)
    {
      return OrderService.getOrder(id)
        .then(response => {
          commit('SET_ORDER', response.data)

          return Promise.resolve(response.data)
        })
        .catch((error) => {
          console.log('There was an error on Order : ', error)
        })
    },
    updateStatus({ commit }, {id, status})
    {
      return OrderService.editOrderStatus(id, { status: status })
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_ORDER', response.data)

          return Promise.resolve(response.data)
        })
    },
    updateListStatus({ commit }, { listId, status })
    {
      return OrderService.editOrderListStatus({ listId: listId, status: status })
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_ORDERS', response.data)

          return Promise.resolve(response.data)
        })
    },

  },
  getters: {
    /**
     * Get Order by id in list orders
     *
     * @param state
     * @returns {function(*): *}
     */
    getOrderById: state => id => {
      return state.orders.find(order => order.id === id)
    }
  }
}
