import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of config env
   * @returns {*}
   */
  getConfigsEnv() {
    return AxiosService.callApi().get('admin/config_env')
  },
  /**
   * Get ConfigEnv by id
   *
   * @param id
   * @returns {*}
   */
  getConfigEnv(id) {
    return AxiosService.callApi().get('/admin/config_env/' + id)
  },
  /**
   * Create Config env
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/config_env', data)
  },
  /**
   * Edit Config Env
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/config_env/' + id, data)
  },
  /**
   * Delete Config Env
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/config_env/' + id)
  }
}
