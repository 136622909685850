import DimensionService from '@/services/DimensionService'

export default {
  state: {
    dimension: {},
    dimensions: []
  },
  mutations: {
    ADD_DIMENSION(state, dimension) {
      state.dimensions.push(dimension)
    },
    SET_DIMENSIONS(state, dimensions) {
      state.dimensions = dimensions
    },
    SET_DIMENSION(state, dimension) {
      state.dimension = dimension
    },
  },
  actions: {
    /**
     * Fetch in state list of dimensions
     *
     * @param commit
     */
    fetchDimensions({ commit }) {
      return DimensionService.getDimensions()
        .then(response => {
          commit('SET_DIMENSIONS', response.data)
          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on Dimension list: ', error)
        })
    },
    /**
     * Fetch in state a dimension
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchDimension({ commit }, id)
    {
      DimensionService.getDimension(id)
        .then(response => {
          commit('SET_DIMENSION', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Dimension : ', error)
        })
    },
    /**
     * Create Dimension
     *
     * @param commit
     * @param dimension
     */
    createDimension({ commit }, dimension)
    {
      return DimensionService.create(dimension)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_DIMENSION', dimension)
          return Promise.resolve()
        })
    },
    /**
     * Update Dimension
     *
     * @param commit
     * @param id
     * @param dimension
     * @returns {*}
     */
    updateDimension({ commit }, { id, dimension })
    {
      return DimensionService.edit(id, dimension)
        .then((response) => {
          if(response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_DIMENSION', response.data)
        })
    },
    /**
     * Delete Dimension
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteDimension({ commit }, {id}) {
      return DimensionService.delete(id)
    }
  },
  getters: {
    /**
     * Get Dimension by id in list dimensions
     *
     * @param state
     * @returns {function(*): *}
     */
    getDimensionById: state => id => {
      return state.dimensions.find(dimension => dimension.id === id)
    }
  }
}
