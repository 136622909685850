<template>
  <div class="container vh-100" id="page-404">
    <div class="row h-100">
      <div class="col-8 mx-auto my-auto text-center">
        <img class="mr-5" :src="require('../../../public/images/logo-adesa.png')" alt="Logo Polygone Béziers" style="width: 70%">

        <h1 class="pt-5 pb-5">Vous êtes perdu ?</h1>

        <a class="" href="/">Retour à l'acceuil ?</a>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'NotFound'
}

</script>
