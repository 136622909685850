<template>
  <div id="page-config-env">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="gear" font-scale="1.5" variant="primary"></b-icon>
        {{ dataPage.title }}
      </h3>
      <div>
        <button class="btn btn-small btn-success" v-on:click="editMode" v-if="!editModeActive">
          <b-icon class="" icon="pencil-square" font-scale="1" variant="white" ></b-icon>
          <span class="ml-2">{{ $t('common.actions.edit') }}</span>
        </button>
        <button class="btn btn-small btn-warning ml-2 mt-1" v-on:click="cancelEditMode" v-if="editModeActive">
          <b-icon class="" icon="x-square" font-scale="1" variant="white"></b-icon>
          <span class="ml-2 text-white">{{ $t('common.cancel') }}</span>
        </button>
        <button
            class="btn btn-small btn-primary ml-2 mt-1"
            v-on:click="update(ConfigEnvModule.configsEnv[0].id)"
            v-if="editModeActive"
        >
          <b-icon class="" icon="check-square" font-scale="1" variant="white"></b-icon>
          <span class="ml-2">{{ $t('common.actions.save') }}</span>
        </button>
      </div>
    </div>

    <hr />

    <div class="col-12 col-md-12 col-lg-12 col-xl-6 p-0">
      {{ /* FTP */ }}
      <h4 class="">Configuration FTP</h4>
      <hr />

      <div class="card shadow">
        <div class="card-body">
          <div class="app-color-text pt-3">
            <label class="font-weight-bold" for="">FTP Url : </label><br />
            <span v-if="!editModeActive">
              {{ (ConfigEnvModule.configsEnv[0]) ? ConfigEnvModule.configsEnv[0].ftpUrl : null }}
            </span>
            <input
                v-if="editModeActive"
                class="form-control"
                id="ftpUrl"
                v-model="ConfigEnvModule.configsEnv[0].ftpUrl"
                type="text"
            >
          </div>
          <div class="app-color-text pt-3">
            <label class="font-weight-bold" for="">FTP Login : </label><br />
            <span v-if="!editModeActive">
              {{ (ConfigEnvModule.configsEnv[0]) ? ConfigEnvModule.configsEnv[0].ftpLogin : null }}
            </span>
            <input
                v-if="editModeActive"
                class="form-control"
                id="ftpLogin"
                v-model="ConfigEnvModule.configsEnv[0].ftpLogin"
                type="text"
            >
          </div>
          <div class="app-color-text pt-3">
            <label class="font-weight-bold" for="">FTP Password : </label><br />
            <span v-if="!editModeActive">
              {{ (ConfigEnvModule.configsEnv[0]) ? ConfigEnvModule.configsEnv[0].ftpPassword : null }}
            </span>
            <input
                v-if="editModeActive"
                class="form-control"
                id="ftpPassword"
                v-model="ConfigEnvModule.configsEnv[0].ftpPassword"
                type="text"
            >
          </div>
        </div>
      </div>

      {{ /* THETIS */ }}
      <h4 class="">Configuration THETIS</h4>
      <hr />

      <div class="col-12 p-0">
        <div class="card shadow">
          <div class="card-body">
            <label class="app-color-text font-weight-bold" for="">THETIS Url : </label><br />
            <span v-if="!editModeActive">
              {{ (ConfigEnvModule.configsEnv[0]) ? ConfigEnvModule.configsEnv[0].thetisUrl : null }}
            </span>
            <input
                v-if="editModeActive"
                class="form-control"
                id="thetisUrl"
                v-model="ConfigEnvModule.configsEnv[0].thetisUrl"
                type="text"
            >
          </div>
        </div>
      </div>

      {{ /* TRACKING */ }}
      <h4 class="">Configuration TRACKING API</h4>
      <hr />

      <div class="col-12 p-0">
        <div class="card shadow">
          <div class="card-body">
            <div class="app-color-text pt-3">
              <label class="font-weight-bold" for="">Tracking API Url : </label><br />
              <span v-if="!editModeActive">{{ ConfigEnvModule.configsEnv[0].trackingApiUrl }}</span>
              <input
                  v-if="editModeActive"
                  class="form-control"
                  id="trackingApiUrl"
                  v-model="ConfigEnvModule.configsEnv[0].trackingApiUrl"
                  type="text"
              >
            </div>
            <div class="app-color-text pt-3">
              <label class="font-weight-bold" for="">Tracking API Token  : </label><br />
              <span v-if="!editModeActive">{{ ConfigEnvModule.configsEnv[0].trackingApiToken }}</span>
              <input
                  v-if="editModeActive"
                  class="form-control"
                  id="trackingApiToken"
                  v-model="ConfigEnvModule.configsEnv[0].trackingApiToken"
                  type="text"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      dataPage: {
        title: 'Configurations application'
      },
      formData: {
        ftpUrl : null,
        ftpLogin: null,
        ftpPassword: null,
        thetisUrl: null,
        trackingApiUrl: null,
        trackingApiToken: null
      },
      editModeActive: false
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchConfigsEnv')

  },
  updated() {
    this.hideLoader()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- EDIT MODE ------------------*/
    editMode()
    {
      this.editModeActive = true
    },
    cancelEditMode()
    {
      this.editModeActive = false

      this.showLoader()

      this.$store.dispatch('fetchConfigsEnv')
        .then(() => {
          this.hideLoader()
        })
    },
    update(id)
    {
      this.editModeActive = false

      this.showLoader()

      // Update
      this.$store.dispatch('updateConfigEnv', { id: id, configEnv: this.$store.getters.getConfigEnvById(id) })
          .then(() => {
            this.hideLoader()

            this.$bvToast.toast(this.$t('pages.configEnv.editSuccess'), {
              title: 'Edition',
              variant: 'success',
              solid: true
            })
          })
    },
    /*----------------- HANDLE INPUT ------------------*/
    handleInput(event, id)
    {
      this.formData = {
        ftpUrl : (id === 'ftpUrl') ? event.target.value : null,
        ftpLogin: (id === 'ftpLogin') ? event.target.value : null,
        ftpPassword: (id === 'ftpPassword') ? event.target.value : null,
        thetisUrl: (id === 'thetisUrl') ? event.target.value : null,
        trackingApiUrl: (id === 'trackingApiUrl') ? event.target.value : null,
        trackingApiToken: (id === 'trackingApiToken') ? event.target.value : null
      }
    }
  },
  computed: {
    ...mapState(['ConfigEnvModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
  }
}
</script>

<style scoped>

</style>
