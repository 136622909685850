import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of user
   *
   * @param data
   * @returns {*}
   */
  getUsers(data) {
    let url = 'admin/users'
    if(data.perPage)
      url += '?_limit=' + data.perPage + '&_page=' + data.page
    if(data.order !== null)
      url += '&_index=' + data.order.index + '&_order=' + data.order.order
    if(data.search !== null)
      url += '&_search=' + data.search
    // Reseller
    if(data.resellerId !== null)
      url += '&_resellerId=' + data.resellerId

    return AxiosService.callApi().get(url)
  },
  /**
   * Get user by token
   *
   * @param token
   * @returns {*}
   */
  getUserByToken(token) {
    return AxiosService.callApi().post('/admin/auth-user', {token: token})
  },
  connectAs(adminId, id) {
    return AxiosService.callApi().post('/admin/users/connect_as/' + adminId + '/' + id)
  },
  /**
   * Get user by id
   *
   * @param id
   * @returns {*}
   */
  getUser(id) {
    return AxiosService.callApi().get('/admin/users/' + id)
  },
  /**
   * Create User
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/users', data)
  },
  /**
   * Edit User
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/users/' + id, data)
  },
  /**
   * Edit User is Active
   *
   * @param id
   * @param data
   * @returns {*}
   */
  editIsActive(id, data) {
    return AxiosService.callApi().put('/admin/users/active/' + id, data)
  },
  /**
   * Delete User
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/users/' + id)
  }
}
