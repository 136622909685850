import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of ProvideNumbersPerRolls
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getProvideNumbersPerRolls(perPage, page, order, search) {
    let url = 'admin/provide-number-per-rolls'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },
  /**
   * Get ProvideNumberPerRolls by id
   *
   * @param id
   * @returns {*}
   */
  getProvideNumberPerRolls(id) {
    return AxiosService.callApi().get('/admin/provide-number-per-rolls/' + id)
  },
  /**
   * Create ProvideNumberPerRolls
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return AxiosService.callApi().post('/admin/provide-number-per-rolls', data)
  },
  /**
   * Edit ProvideNumberPerRolls
   *
   * @param id
   * @param data
   * @returns {*}
   */
  edit(id, data) {
    return AxiosService.callApi().put('/admin/provide-number-per-rolls/' + id, data)
  },
  /**
   * Delete ProvideNumberPerRolls
   *
   * @param id
   * @returns {*}
   */
  delete(id) {
    return AxiosService.callApi().delete('/admin/provide-number-per-rolls/' + id)
  }
}
