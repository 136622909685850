<template>
  <div id="page-reseller-edit">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <div>
        <h5>
          {{ $t('pages.reseller.id.title') }}:
          <strong>{{ ResellerModule.reseller.firstname }}  {{ ResellerModule.reseller.lastname }}</strong> ({{ ResellerModule.reseller.username }})
        </h5>
      </div>
      <div>
        <router-link
            class="btn btn-outline-primary mb-3"
            :to="{ name: 'reseller-index' }"
        >
          {{ $t('common.back') }}
        </router-link>
      </div>
    </div>

    {{ /* Form component */ }}
    <FormCrudReseller
        :reseller="ResellerModule.reseller"
        :id="ResellerModule.reseller.id"
        :selected-type="getSelectedType"
        :actionForm="'edit'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchReseller', this.$route.params.id)
  },
  updated() {
    this.hideLoader()
  },
  computed: {
    /**
     * Set var selectedType
     */
    getSelectedType()
    {
      return (this.ResellerModule.reseller && this.ResellerModule.reseller.type) ? this.ResellerModule.reseller.type : null
    },
    ...mapState(['ResellerModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    FormCrudReseller: () => import('@/components/pages/reseller/FormCrudReseller')
  }
}
</script>
