<template>
  <div id="dashboard-page-index">
    <DashboardAdmin v-if="$store.getters.userIsAdmin"></DashboardAdmin>
    <DashboardReseller v-else-if="$store.getters.userIsReseller"></DashboardReseller>
  </div>
</template>

<script>

export default {
  components: {
    DashboardAdmin: () => import('@/components/pages/dashboard/DashboardAdmin'),
    DashboardReseller: () => import('@/components/pages/dashboard/DashboardReseller')
  }
}
</script>
