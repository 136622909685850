import CategoryScenarioService from '@/services/CategoryScenarioService'

export default {
  state: {
    categoryScenario: {},
    categoriesScenario: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_CATEGORY_SCENARIO(state, categoryScenario) {
      state.categoriesScenario.push(categoryScenario)
    },
    SET_CATEGORIES_SCENARIO(state, categoriesScenario) {
      state.categoriesScenario = categoriesScenario
    },
    SET_CATEGORIES_SCENARIO_TOTAL(state, total) {
      state.total = total
    },
    SET_CATEGORIES_SCENARIO_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_CATEGORY_SCENARIO(state, categoryScenario) {
      state.categoryScenario = categoryScenario
    },
  },
  actions: {
    /**
     * Fetch in state list of categoriesScenario
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchCategoriesScenario({ commit }, { perPage, page, order, search }) {
      return CategoryScenarioService.getCategoriesScenario(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_CATEGORIES_SCENARIO', response.data.list)
          commit('SET_CATEGORIES_SCENARIO_TOTAL', response.data.total)
          commit('SET_CATEGORIES_SCENARIO_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on categoriesScenario list: ', error)
        })
    },
    /**
     * Fetch in state a categoriesScenario
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCategoryScenario({ commit }, id)
    {
      CategoryScenarioService.getCategoryScenario(id)
        .then(response => {
          commit('SET_CATEGORY_SCENARIO', response.data)
        })
        .catch((error) => {
          console.log('There was an error on CategoryScenario : ', error)
        })
    },
    /**
     * Create CategoryScenario
     *
     * @param commit
     * @param categoryScenario
     */
    createCategoryScenario({ commit }, categoryScenario)
    {
      return CategoryScenarioService.create(categoryScenario)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_CATEGORY_SCENARIO', categoryScenario)
          return Promise.resolve()
        })
    },
    /**
     * Update CategoryScenario
     *
     * @param commit
     * @param id
     * @param categoryScenario
     * @returns {*}
     */
    updateCategoryScenario({ commit }, {id, categoryScenario})
    {
      return CategoryScenarioService.edit(id, categoryScenario)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_CATEGORY_SCENARIO', response.data)
        })
    },
    /**
     * Delete CategoryScenario
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteCategoryScenario({ commit }, {id}) {
      return CategoryScenarioService.delete(id)
    }
  },
  getters: {
    /**
     * Get CategoryScenario by id in list categoriesScenario
     *
     * @param state
     * @returns {function(*): *}
     */
    getCategoryScenarioById: state => id => {
      return state.categoriesScenario.find(categoryScenario => categoryScenario.id === id)
    }
  }
}
