import axios from 'axios'

const baseAPIUrl = process.env.VUE_APP_API_URL

import store from '../store/index'

export default {
  /**
   * Call API for all request
   *
   * @returns {*}
   */
  callApi() {
    return axios
      .create({
        baseURL: baseAPIUrl,
        withCredentials: false,
        params: {
          locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fr'
        },
        validateStatus: function (status) {

          // ERROR 400
          if(status === 400) {
            return axios.Cancel
          }
          // ERROR 401
          else if(status === 401) {
            // Disconnect
            store.dispatch('logout', true)
            window.location.href = 'login'

            return axios.Cancel
          }

          return status >= 200 && status < 300 // default
        },
        headers: {
          common: {
            Accept: 'application/json'
          },
          patch: {
            'Content-Type': 'application/json'
          },
          Authorization: 'Bearer ' + localStorage.getItem('auth.userToken')
        }
      })
  },
  /**
   * Get user token
   *
   * @param data
   * @returns {*}
   */
  getToken(data) {
    return axios.post(baseAPIUrl + '/login', data)
  }
}
