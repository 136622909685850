
export const ordersExportProperties = [
    {
        propertyName: 'id',
        propertyLabel: 'exportProperties.id',
        isSelected: true
    },
    {
        propertyName: 'company',
        propertyLabel: 'exportProperties.company',
        isSelected: true
    },
    {
        propertyName: 'scenario',
        propertyLabel: 'exportProperties.scenario',
        isSelected: true
    },
    {
        propertyName: 'total',
        propertyLabel: 'exportProperties.total',
        isSelected: true
    },
    {
        propertyName: 'weight',
        propertyLabel: 'exportProperties.weight',
        isSelected: true
    },
    {
        propertyName: 'width',
        propertyLabel: 'exportProperties.width',
        isSelected: true
    },
    {
        propertyName: 'height',
        propertyLabel: 'exportProperties.height',
        isSelected: true
    },
    {
        propertyName: 'status',
        propertyLabel: 'exportProperties.status',
        isSelected: true
    },
    {
        propertyName: 'createdAt',
        propertyLabel: 'exportProperties.createdAt',
        isSelected: true
    },
    {
        propertyName: 'pdfManufacturingUrl',
        propertyLabel: 'exportProperties.pdfManufacturingUrl',
        isSelected: true
    },
    {
        propertyName: 'reference',
        propertyLabel: 'exportProperties.reference',
        isSelected: true
    },
    {
        propertyName: 'sellingPrice',
        propertyLabel: 'exportProperties.sellingPrice',
        isSelected: true
    },
    {
        propertyName: 'application',
        propertyLabel: 'exportProperties.application',
        isSelected: true
    },
    {
        propertyName: 'mandrel',
        propertyLabel: 'exportProperties.mandrel',
        isSelected: true
    },
    {
        propertyName: 'quantityPerRoll',
        propertyLabel: 'exportProperties.quantityPerRoll',
        isSelected: true
    },
    {
        propertyName: 'quantityPerSeries',
        propertyLabel: 'exportProperties.quantityPerSeries',
        isSelected: true
    },
    {
        propertyName: 'files',
        propertyLabel: 'exportProperties.files',
        isSelected: true
    },
    {
        propertyName: 'customerName',
        propertyLabel: 'exportProperties.customerName',
        isSelected: true
    },
    {
        propertyName: 'address',
        propertyLabel: 'exportProperties.address',
        isSelected: true
    },
    {
        propertyName: 'postalCode',
        propertyLabel: 'exportProperties.postalCode',
        isSelected: true
    },
    {
        propertyName: 'city',
        propertyLabel: 'exportProperties.city',
        isSelected: true
    },
    {
        propertyName: 'countryCode',
        propertyLabel: 'exportProperties.countryCode',
        isSelected: true
    },
    // {
    //     propertyName: 'orderTracking',
    //     propertyLabel: 'exportProperties.orderTracking',
    //     isSelected: true
    // },

]

export const quotesExportProperties = [
    {
        propertyName: 'id',
        propertyLabel: 'exportProperties.id',
        isSelected: true
    },
    {
        propertyName: 'company',
        propertyLabel: 'exportProperties.company',
        isSelected: true
    },
    {
        propertyName: 'scenario',
        propertyLabel: 'exportProperties.scenario',
        isSelected: true
    },
    {
        propertyName: 'price',
        propertyLabel: 'exportProperties.total',
        isSelected: true
    },
    {
        propertyName: 'weight',
        propertyLabel: 'exportProperties.weight',
        isSelected: true
    },
    {
        propertyName: 'width',
        propertyLabel: 'exportProperties.width',
        isSelected: true
    },
    {
        propertyName: 'height',
        propertyLabel: 'exportProperties.height',
        isSelected: true
    },
    {
        propertyName: 'diameter',
        propertyLabel: 'exportProperties.diameter',
        isSelected: true
    },
    {
        propertyName: 'createdAt',
        propertyLabel: 'exportProperties.createdAt',
        isSelected: true
    },
    {
        propertyName: 'reference',
        propertyLabel: 'exportProperties.reference',
        isSelected: true
    },
    {
        propertyName: 'application',
        propertyLabel: 'exportProperties.application',
        isSelected: true
    },
    {
        propertyName: 'mandrel',
        propertyLabel: 'exportProperties.mandrel',
        isSelected: true
    },
    {
        propertyName: 'quantityPerRoll',
        propertyLabel: 'exportProperties.quantityPerRoll',
        isSelected: true
    },
    {
        propertyName: 'quantityPerSeries',
        propertyLabel: 'exportProperties.quantityPerSeries',
        isSelected: true
    },
    {
        propertyName: 'files',
        propertyLabel: 'exportProperties.files',
        isSelected: true
    }
]


export const getOrdersExportProperties = () => {


}