import PaperService from '@/services/PaperService'

export default {
  state: {
    paper: {},
    papers: [],
    total: null,
    totalRequest: null,
  },
  mutations: {
    ADD_PAPER(state, paper) {
      state.papers.push(paper)
    },
    SET_PAPERS(state, papers) {
      state.papers = papers
    },
    SET_PAPERS_TOTAL(state, total) {
      state.total = total
    },
    SET_PAPERS_TOTAL_REQUEST(state, totalRequest) {
      state.totalRequest = totalRequest
    },
    SET_PAPER(state, paper) {
      state.paper = paper
    },
  },
  actions: {
    /**
     * Fetch in state list of papers
     *
     * @param commit
     * @param perPage
     * @param page
     * @param order
     * @param search
     */
    fetchPapers({ commit }, { perPage, page, order, search }) {
      return PaperService.getPapers(
        (perPage) ? perPage: null,
        (page) ? page: null,
        (order) ? order : null,
        (search) ? search : null)
        .then(response => {
          commit('SET_PAPERS', response.data.list)
          commit('SET_PAPERS_TOTAL', response.data.total)
          commit('SET_PAPERS_TOTAL_REQUEST', response.data.totalRequest)

          return Promise.resolve(response.data.list)
        })
        .catch(error => {
          console.log('There was an error on Paper list: ', error)
        })
    },
    /**
     * Fetch in state a paper
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchPaper({ commit }, id)
    {
      PaperService.getPaper(id)
        .then(response => {
          commit('SET_PAPER', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Paper : ', error)
        })
    },
    /**
     * Create Paper
     *
     * @param commit
     * @param paper
     */
    createPaper({ commit }, paper)
    {
      return PaperService.create(paper)
        .then((response) => {
          // Check if has errors on form
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_PAPER', paper)
          return Promise.resolve()
        })
    },
    /**
     * Update paper
     *
     * @param commit
     * @param id
     * @param paper
     * @returns {*}
     */
    updatePaper({ commit }, {id, paper})
    {
      return PaperService.edit(id, paper)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_PAPER', response.data)
        })
    },
    /**
     * Delete Paper
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deletePaper({ commit }, {id}) {
      return PaperService.delete(id)
    }
  },
  getters: {
    /**
     * Get Paper by id in list papers
     *
     * @param state
     * @returns {function(*): *}
     */
    getPaperById: state => id => {
      return state.papers.find(paper => paper.id === id)
    }
  }
}
