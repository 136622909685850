<template>
  <div id="page-dimension">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="bounding-box" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.dimension.title') }}
      </h3>
      <div>
        <router-link
            class="btn btn-info mt-2"
            :to="{ name: 'dimension-create' }"
        >
          {{ $t('pages.dimension.createDimension') }}
        </router-link>
      </div>
    </div>

    <hr />

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="DimensionModule.dimensions.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="DimensionModule.dimensions"
            :entityName="'dimension'"
            :actionList="configTableComponent.actionList"
            :specificAction="configTableComponent.specificAction"
            :isAction="true"
            @specific-action="handleSpecificAction"
        />
        <div v-else class="text-center mt-5">
          {{ $t('pages.dimension.noResultList') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'center', 'filter': false },
          {'index' : 'name', 'name' : 'Type', 'align' : 'center', 'filter': false },
          {'index' : 'height', 'indexTrad' : 'common.height', 'align' : 'center', 'filter': false, 'centimeter': true },
          {'index' : 'width', 'indexTrad' : 'common.width', 'align' : 'center', 'filter': false, 'centimeter': true },
          {'index' : 'category', 'indexTrad' : 'common.singleCategory', 'align' : 'center', 'filter': false, 'relation': true },
        ],
        actionList: [
          {
            'indexTrad': 'common.actions.edit',
            'routeName' : 'dimension-edit',
            'icon' : 'pencil-square',
            'icon-variant': 'info',
            'params' : true ,
            'link-action': true
          },
          {
            'index': 'delete',
            'indexTrad' : 'common.actions.delete',
            'icon' : 'trash',
            'icon-variant': 'danger',
            'isSpecificAction' : true
          },
        ]
      }
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchDimensions')
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.hideLoader()
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /**
     * handle specific action (emit from table component)
     *
     * @param obj
     */
    handleSpecificAction(obj) {
      // Delete
      if(obj.action === 'delete') {
        this.specificActionDelete(obj)
      }
    },
    specificActionDelete(obj) {
      this.$swal({
        title: this.$t('pages.dimension.wantDelete'),
        text: this.$t('common.actionIrreversible'),
        showCancelButton: true,
        confirmButtonColor: '#fa5c7c',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('common.actions.delete')
      }).then((result) => {

        if (result.value) {
          this.showLoader()
          this.$store.dispatch('deleteDimension', { id: obj.id })
              .then(() => {
                this.$bvToast.toast(this.$t('pages.dimension.deleteSuccess'), {
                  variant: 'success',
                  solid: true
                })

                this.$store.dispatch('fetchDimensions')

                this.hideLoader()
              })
              .catch((error) => {
                this.hideLoader()

                this.$bvToast.toast(error.message, {
                  title: this.$t('pages.dimension.errorDeleteTitle'),
                  variant: 'warning',
                  solid: true
                })
              })
        }
      })
    }
  },
  computed: {
    ...mapState(['DimensionModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent')
  }
}
</script>

<style scoped>

</style>
