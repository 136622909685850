import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Check if user exist with his email
   * @param email
   * @returns {*}
   */
  checkUserByEmail(email) {
    return AxiosService.callApi().post('/reset-password/check-user-mail', email)
  },
  /**
   * Send mail for forgot password
   *
   * @returns {*}
   */
  sendRequestForgotPassword(id) {
    return AxiosService.callApi().get('/reset-password/' + id)
  },
  /**
   * Update password
   *
   * @param token
   * @param id
   * @param password
   * @returns {*}
   */
  changePasswordRequest(token, id, password) {
    return AxiosService.callApi().post('/reset-password/change_password/' + token + '/' + id, password)
  }
}
