import AxiosService from '@/services/AxiosServices'

export default {
  /**
   * Get list of shapes
   *
   * @param perPage => limit
   * @param page => item by page
   * @param order => obj with index and ASC or DESC
   * @param search => research
   * @returns {*}
   */
  getShapes(perPage, page, order, search) {
    let url = 'shapes'
    if(perPage)
      url += '?_limit=' + perPage + '&_page=' + page
    if(order !== null)
      url += '&_index=' + order.index + '&_order=' + order.order
    if(search !== null)
      url += '&_search=' + search

    return AxiosService.callApi().get(url)
  },

  getShape(id) {
    return AxiosService.callApi().get('/shapes/' + id)
  },

  create(data) {
    return AxiosService.callApi().post('/shapes', data)
  },

  edit(id, data) {
    return AxiosService.callApi().put('/shapes/' + id, data)
  },

  delete(id) {
    return AxiosService.callApi().delete('/shapes/' + id)
  }
}
