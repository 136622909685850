<template>
  <div id="page-order-request-cancel">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="x-circle" font-scale="1.5" variant="danger"></b-icon>
        {{ $t('pages.orderCancel.title') }}
      </h3>
    </div>

    <hr />

    <div class="d-xl-flex justify-content-between">
      <div class="d-xl-flex mb-4 w-100">
        <div class="search d-flex mt-2 mt-md-0">
          <div class="input-group">
            <input type="text" class="form-control border" v-model="filter.search" :placeholder="$t('common.search.keyword')" v-on:keyup.enter="sendResearchRequest">
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append">
              <button class="btn btn-success p-0 pl-2 pr-2" v-on:click="sendResearchRequest">
                {{ $t('common.search.research') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow">
      <div class="card-body p-0">
        <TableComponent
            v-if="OrderRequestCancelModule.requestCancels.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="OrderRequestCancelModule.requestCancels"
            :entityName="'orderRequestCancel'"
            :actionList="(this.$store.getters.userIsAdmin) ? configTableComponent.actionList : null"
            :specificAction="configTableComponent.specificAction"
            :isAction="!!(this.$store.getters.userIsAdmin)"
            @ordered="clickOrdered"
            @specific-action="respondRequestCancel"
        />

        <div v-if="OrderRequestCancelModule.requestCancels.length === 0">
          <div v-if="filter.search != null" class="text-center mt-5">
            {{ $t('common.search.noResult') }}
          </div>
          <div v-else class="text-center mt-5">
            {{ $t('pages.orderCancel.noResultList') }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="filter-elem-per-page">
              <select class="form-control" v-model="filter.elemPerPage" v-on:change="changeLimitList">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div class="count-elem my-auto ml-3">
              <b>
                {{ (filter.search)
                  ? OrderRequestCancelModule.totalRequest
                  : OrderRequestCancelModule.total
                }}
              </b> {{ $t('common.result') }}
            </div>
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="OrderRequestCancelModule.requestCancels.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="
                (OrderRequestCancelModule.totalRequest > 0)
                ? Math.ceil(OrderRequestCancelModule.totalRequest / filter.elemPerPage)
                : Math.ceil(OrderRequestCancelModule.total / filter.elemPerPage)
                "
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 25,
        order: {
          index: 'createdAt',
          order: 'DESC'
        },
        search: null,
        listFilterStatus: []
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true },
          {'index' : 'createdAt', 'indexTrad' : 'pages.orderCancel.tableList.dateRequest', 'align' : 'center', 'filter': true, 'date' : true, 'datetime': true, 'order': 'DESC' },
          {'index' : 'status', 'indexTrad' : 'pages.orderCancel.tableList.statusRequest', 'align' : 'center', 'filter': true },
          {'index' : 'reason', 'indexTrad' : 'common.reason', 'align' : 'center', 'filter': true },
          {'index' : 'user', 'indexTrad' : 'common.company', 'align' : 'center', 'filter': true, 'relation': true },
          {'index' : 'order', 'indexTrad' : 'common.singleScenario', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-quote-scenario' },
          {'index' : 'order', 'indexTrad' : 'pages.orderCancel.tableList.apiNumberOrder', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-id' },
          {'index' : 'order', 'indexTrad' : 'common.orderStatus', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-status' },
          {'index' : 'order', 'indexTrad' : 'pages.orderCancel.tableList.creationOrderDate', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'order-createdAt' },
        ],
        actionList: [
          {
            'index': 'approved',
            'indexTrad' : 'common.approved',
            'icon' : 'hand-thumbs-up',
            'icon-variant': 'success',
            'isAdminOnly' : true,
            'isSpecificAction' : true
          },
          {
            'index': 'unapproved',
            'indexTrad' : 'common.unapproved',
            'icon' : 'hand-thumbs-down',
            'icon-variant': 'danger',
            'isAdminOnly' : true,
            'isSpecificAction' : true
          }
        ]
      }
    }
  },
  created() {
    this.showLoader()

    this.$store.dispatch('fetchOrderRequestCancels', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page,
      order: this.filter.order,
      search: (this.filter.search) ? this.filter.search : null,
      filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
      resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
    })
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.hideLoader()
      })
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SELECT LIMIT LIST ------------------*/
    changeLimitList() {
      this.showLoader()

      this.$store.dispatch('fetchOrderRequestCancels', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SELECT FILTER ------------------*/
    changeFilterStatus(event) {
      this.showLoader()

      if(!isNaN(event.target.value)) {
        if(!this.filter.listFilterStatus.includes(Number(event.target.value))) {
          this.filter.listFilterStatus.push(Number(event.target.value))
        }

        this.$store.dispatch('fetchOrderRequestCancels', {
          perPage: this.filter.elemPerPage,
          page: this.filter.page,
          order: (this.filter.order) ? this.filter.order : null,
          search: (this.filter.search) ? this.filter.search : null,
          filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
          resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
        })
            .then(() => {
              this.hideLoader()
            })
      }
    },
    removeFilterStatus(statusId) {
      this.showLoader()

      let index = this.filter.listFilterStatus.findIndex(id => id === statusId)
      if(index > -1) {
        this.filter.listFilterStatus.splice(index, 1)
      }

      this.$store.dispatch('fetchOrderRequestCancels', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()

      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchOrderRequestCancels', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrderRequestCancels', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrderRequestCancels', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.filter.order = order

      this.$store.dispatch('fetchOrderRequestCancels', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SPECIFIC ACTION TABLE COMPONENT ------------------*/
    respondRequestCancel(obj) {
      // Get Order request cancel
      let orderRequestCancel = this.$store.getters.getOrderRequestCancelById(obj.id)

      // Security
      if(!orderRequestCancel) {
        this.$bvToast.toast(this.$t('pages.orderCancel.requestNotFound'), {
          title: this.$t('common.error').toUpperCase(),
          variant: 'danger',
          solid: true
        })
      }

      if(obj.action === 'approved') {
        this.handleDispatchStatus(orderRequestCancel, obj, true)
      } else if(obj.action === 'unapproved') {
        this.handleDispatchStatus(orderRequestCancel, obj, false)
      } else if(obj.action === 'cancel') {
        this.handleDispatchStatusCancel(orderRequestCancel)
      }
    },
    handleDispatchStatus(orderRequestCancel, obj, isApproved)
    {
      let isAcceptStr = ((isApproved) ? this.$t('common.accept') : this.$t('common.refuse'))
      let swalTitle = this.$t('common.doYouWant') + isAcceptStr + " " +  this.$t('pages.orderCancel.theCancelRequest').toLowerCase() + ' ?'

      this.$swal({
        title: swalTitle,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: (isApproved) ? '#0acf97' : '#fa5c7c',
        cancelButtonColor: '#929292',
        confirmButtonText: isAcceptStr,
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          // Loader
          this.showLoader()

          // Call API
          this.$store.dispatch(
              'updateOrderRequestCancelStatus',
              { id: orderRequestCancel.id, orderRequestCancel: orderRequestCancel, respond: obj.action }
          )
              .then(() => {
                this.hideLoader()

                this.$bvToast.toast(this.$t('pages.orderCancel.ask') + ' ' + isAcceptStr, {
                  title: this.$t('pages.orderCancel.theCancelRequest') + ' ' + this.$t('common.hasBeen') + ' ' + isAcceptStr,
                  variant: (isApproved) ? 'success': 'danger',
                  solid: true
                })

                this.$store.dispatch('fetchOrderRequestCancels', {
                  perPage: this.filter.elemPerPage,
                  page: this.filter.page,
                  order: (this.filter.order) ? this.filter.order : null,
                  search: (this.filter.search) ? this.filter.search : null,
                  filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
                  resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
                })
                    .then(() => {
                      this.hideLoader()
                    })
              })
              .catch(() => {
                this.hideLoader()
              })
        }
      });
    },
    handleDispatchStatusCancel(orderRequestCancel)
    {
      this.$swal({
        title: this.$t('pages.orderCancel.doYouWantCancelTreatment'),
        text: this.$t('pages.orderCancel.resetRequest'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#fa5c7c',
        cancelButtonColor: '#929292',
        confirmButtonText: this.$t('pages.orderCancel.cancelRequest'),
        cancelButtonText: this.$t('common.cancel')
      }).then((result) => {
        if (result.value) {
          // Loader
          this.showLoader()

          // Call API
          this.$store.dispatch(
              'cancelRespond',
              { id: orderRequestCancel.id, orderRequestCancel: orderRequestCancel, respond: orderRequestCancel.status }
          )
              .then(() => {
                this.hideLoader()

                this.$bvToast.toast(this.$t('pages.orderCancel.ask') + this.$t('common.cancel').toUpperCase(), {
                  title: this.$t('pages.orderCancel.askCancelRequest'),
                  variant: 'success',
                  solid: true
                })

                this.$store.dispatch('fetchOrderRequestCancels', {
                  perPage: this.filter.elemPerPage,
                  page: this.filter.page,
                  order: (this.filter.order) ? this.filter.order : null,
                  search: (this.filter.search) ? this.filter.search : null,
                  filters: (this.filter.listFilterStatus.length > 0) ? this.filter.listFilterStatus : null,
                  resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
                })
                    .then(() => {
                      this.hideLoader()
                    })
              })
              .catch(() => {
                this.hideLoader()
              })
        }
      });
    }
  },
  computed: {
    ...mapState(['OrderRequestCancelModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination')
  }
}
</script>
