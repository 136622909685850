import DashboardService from '@/services/DashboardService'

export default {
  state: {
    counters: {},
    charts: {},
    cumulatedTurnoverChart: {}
  },
  mutations: {
    SET_COUNTERS(state, counters) {
      state.counters = counters
    },
    SET_CHARTS(state, charts) {
      state.charts = charts
    },
    SET_CUMULATED_TURNOVER_CHART(state, cumulatedTurnoverChart) {
      state.cumulatedTurnoverChart = cumulatedTurnoverChart
    }
  },
  actions: {
    /**
     * Fetch in state a counters
     *
     * @param commit
     * @param data
     */
    fetchCounters({ commit }, data)
    {
      DashboardService.getCounters(data)
        .then(response => {
          commit('SET_COUNTERS', response.data)

          return Promise.resolve(response.data)
        })
        .catch((error) => {
          console.log('There was an error on Dashboard counters : ', error)
        })
    },
    /**
     * Fetch data for charts
     *
     * @param commit
     * @param data
     */
    fetchCharts({ commit }, data)
    {
      DashboardService.getCharts(data)
        .then(response => {
          commit('SET_CHARTS', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Dashboard charts data : ', error)
        })
    },
    fetchCumulatedTurnoverChart({ commit }, data)
    {
      DashboardService.getCumulatedTurnoverChart(data)
        .then(response => {
          commit('SET_CUMULATED_TURNOVER_CHART', response.data)
        })
        .catch((error) => {
          console.log('There was an error on Dashboard charts data : ', error)
        })
    },
  },
  getters: {

  }
}
