import CmsCalculatorService from '@/services/CmsCalculatorService'

export default {
  state: {
    cmsCalculatorEntry: {},
    cmsCalculatorEntries: [],
  },
  mutations: {
    ADD_CMS_CALCULATOR_ENTRY(state, cmsCalculatorEntry) {
      state.cmsCalculatorEntries.push(cmsCalculatorEntry)
    },
    SET_CMS_CALCULATOR_ENTRIES(state, cmsCalculatorEntries) {
      state.cmsCalculatorEntries = cmsCalculatorEntries
    },
    SET_CMS_CALCULATOR_ENTRY(state, cmsCalculatorEntry) {
      state.cmsCalculatorEntry = cmsCalculatorEntry
    },
  },
  actions: {
    /**
     * Fetch in state list of cmsCalculator
     *
     * @param commit
     * @param typeCmsCalculator
     */
    fetchCmsCalculatorEntries({ commit }, typeCmsCalculator) {
      return CmsCalculatorService.getCmsCalculatorEntries(typeCmsCalculator)
        .then(response => {
          commit('SET_CMS_CALCULATOR_ENTRIES', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on CmsCalculator list: ', error)
        })
    },
    /**
     * Fetch in state a CmsCalculatorEntry
     *
     * @param commit
     * @param getters
     * @param id
     */
    fetchCmsCalculatorEntry({ commit }, id)
    {
      CmsCalculatorService.getCmsCalculatorEntry(id)
        .then(response => {
          commit('SET_CMS_CALCULATOR_ENTRY', response.data)
        })
        .catch((error) => {
          console.log('There was an error on CmsCalculator : ', error)
        })
    },
    /**
     * Create CmsCalculator
     *
     * @param commit
     * @param cmsCalculatorEntry
     */
    createCmsCalculatorEntry({ commit }, cmsCalculatorEntry)
    {
      return CmsCalculatorService.create(cmsCalculatorEntry)
        .then((response) => {
          // Check if has errors on form
          if (response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('ADD_CMS_CALCULATOR_ENTRY', cmsCalculatorEntry)
          return Promise.resolve(response.data)
        })
    },
    /**
     * Update cmsCalculatorEntry
     *
     * @param commit
     * @param id
     * @param cmsCalculatorEntry
     * @returns {*}
     */
    updateCmsCalculatorEntry({ commit }, { id, cmsCalculatorEntry })
    {
      return CmsCalculatorService.edit(id, cmsCalculatorEntry)
        .then((response) => {
          if (response.data.errors) {
            return Promise.reject(response.data.errors)
          }

          commit('SET_CMS_CALCULATOR_ENTRY', response.data)
        })
    },
    /**
     * Delete cmsCalculatorEntry
     *
     * @param commit
     * @param id
     * @returns {*}
     */
    // eslint-disable-next-line no-unused-vars
    deleteCmsCalculatorEntry({ commit }, id) {
      return CmsCalculatorService.delete(id)
    }
  },
  getters: {
    /**
     * Get cmsCalculatorEntry by id in list CmsCalculatorEntries
     *
     * @param state
     * @returns {function(*): *}
     */
    getCmsCalculatorEntryById: state => id => {
      return state.cmsCalculatorEntries.find(cmsCalculatorEntry => cmsCalculatorEntry.id === id)
    }
  }
}
